import { difference, uniq, uniqueId } from "lodash";
import { SelectableTreeItemData, TreeItemData } from ".";

/**
 * When a node is (de)selected we do the following to expand the selected node in
 * the tree:
 *
 * - Select all children of a node that was just selected
 * - Select a parent if all children are selected
 * - Deselect a parent if not all children are selected
 * - Deselect all children of a node that was just deselected
 */
export function expandSelection(
  checked: boolean,
  itemId: string,
  prevSelection: string[],
  items: SelectableTreeItemData[]
): string[] {
  const item = getById(items, itemId);
  if (!item) return prevSelection;
  return checked
    ? expandCheckedItem(item, prevSelection)
    : expandUncheckedItem(item, prevSelection);
}

function expandCheckedItem(
  item: SelectableTreeItemData,
  prevSelection: string[]
) {
  // Add item to selection
  let selection = [item.treeItemId, ...prevSelection];

  // Add all descendants to selection
  const descendantIds = getDescendants(item).map(x => x.treeItemId);
  selection = [...selection, ...descendantIds];

  // For each ancestor, if all children are selected, add ancestor to selection
  // Notice; the order of ancestors is important, we want to traverse from the
  // bottom of the tree up.
  const ancestors = getAncestors(item);
  for (const ancestor of ancestors) {
    const isAllChildrenSelected = isAllItemsSelected(
      selection,
      ancestor.children
    );
    if (isAllChildrenSelected) {
      selection = [...selection, ancestor.treeItemId];
    }
  }
  return uniq(selection);
}

function expandUncheckedItem(
  item: SelectableTreeItemData,
  prevSelection: string[]
) {
  // Remove item from selection
  let selection = prevSelection.filter(x => x !== item.treeItemId);

  // Remove all ancestors from selection (no parent, or ancestor can be checked if any child is unchecked)
  const ancestorIds = getAncestors(item).map(x => x.treeItemId);
  selection = selection.filter(x => !ancestorIds.includes(x));

  // Remove all descendants from selection
  const descendantIds = getDescendants(item).map(x => x.treeItemId);
  selection = selection.filter(x => !descendantIds.includes(x));

  return selection;
}

export function mapToSelectableTreeItemData(
  items: TreeItemData[],
  parent?: SelectableTreeItemData,
  customKeyFunction?: (item: TreeItemData) => string
): SelectableTreeItemData[] {
  return items.map<SelectableTreeItemData>(item => {
    const selectableTreeItem: SelectableTreeItemData = {
      ...item,
      parent: parent,
      treeItemId: customKeyFunction?.(item) ?? uniqueId("tree-item-"),
      children: []
    };
    selectableTreeItem.children = mapToSelectableTreeItemData(
      item.children,
      selectableTreeItem,
      customKeyFunction
    );
    return selectableTreeItem;
  });
}

export function getDescendants(
  node: SelectableTreeItemData | undefined
): SelectableTreeItemData[] {
  return node ? node.children.flatMap(x => [x, ...getDescendants(x)]) : [];
}

export function getAncestors(
  node: SelectableTreeItemData
): SelectableTreeItemData[] {
  return node?.parent ? [node.parent, ...getAncestors(node.parent)] : [];
}

function getById(
  items: SelectableTreeItemData[],
  treeItemId: string
): SelectableTreeItemData | undefined {
  return flatten(items).find(x => x.treeItemId === treeItemId);
}

export function getByIds(
  items: SelectableTreeItemData[],
  treeItemIds: string[]
): SelectableTreeItemData[] {
  return flatten(items).filter(x => treeItemIds.includes(x.treeItemId));
}

export function getAllIds(items: SelectableTreeItemData[]) {
  return flatten(items).map(x => x.treeItemId);
}

export function getNotSelectedItems(
  selected: string[],
  items: SelectableTreeItemData[]
): SelectableTreeItemData[] {
  const notSelectedIds = difference(getAllIds(items), selected);
  return getByIds(items, notSelectedIds);
}

export function getRemovedItems(
  selected: string[],
  items: SelectableTreeItemData[]
): SelectableTreeItemData[] {
  const notSelectedIds = difference(getAllIds(items), selected);
  // remove parent nodes recursively of elements in selected from notSelectedIds
  const selectedParents = getAncestors(getById(items, selected[0])!).map(
    x => x.treeItemId
  );

  const notSelectedIdsWithoutParents = notSelectedIds.filter(
    x => !selectedParents.includes(x)
  );

  return getByIds(items, notSelectedIdsWithoutParents);
}

export function flatten(
  items: SelectableTreeItemData[] = []
): SelectableTreeItemData[] {
  return items.reduce(
    (prev, item) => [...prev, ...flatten(item.children)],
    items
  );
}

function isItemsSelected(
  items: SelectableTreeItemData[],
  selection: string[]
): boolean {
  return items.map(x => x.treeItemId).every(i => selection.includes(i));
}

export function isAllItemsSelected(
  selection: string[],
  items: SelectableTreeItemData[]
): boolean {
  return isItemsSelected(flatten(items), selection);
}

export function isAnyDescendantsSelected(
  selection: string[],
  item: SelectableTreeItemData
): boolean {
  const descendantIds = getDescendants(item).map(x => x.treeItemId);
  return descendantIds.some(i => selection.includes(i));
}
