import { useEffect } from "react";
import {
  PdfViewerVersion11534,
  RhinestonePDFJSCustomTypeDef
} from "../pdfjs-version-1_1_534-types";
import { findBarClassNames } from "../../../FindBar/FindBar";
import { iconMenuClassNames } from "../../../../DocumentActionMenu";

// pdfjs is globally available
declare let PDFJS: RhinestonePDFJSCustomTypeDef;
export function useSetupFindBar(
  findBar: Element | null | undefined,
  pdfViewer: PdfViewerVersion11534 | undefined
) {
  useEffect(() => {
    if (!findBar || !pdfViewer) return;
    setupFindController(pdfViewer, findBar);
  }, [pdfViewer, findBar]);
}

function setupFindController(
  pdfViewer: PdfViewerVersion11534,
  searchBarElement: Element
) {
  const findController = new PDFJS.PDFFindController({
    pdfViewer
  });
  findController.listenWindowEvents();

  const globalToggles = getGlobalToggleElementsForFindBar();
  const findBar = new PDFJS.PDFFindBar({
    findController,
    bar: searchBarElement,
    findField: searchBarElement.querySelector(
      `.${findBarClassNames.findInput}`
    ),
    toggleButton: [
      searchBarElement.querySelector(`.${findBarClassNames.findToggle}`),
      ...globalToggles
    ],
    findPreviousButton: searchBarElement.querySelector(
      `.${findBarClassNames.findPrevious}`
    ),
    findNextButton: searchBarElement.querySelector(
      `.${findBarClassNames.findNext}`
    ),
    findMsg: searchBarElement.querySelector(
      `.${findBarClassNames.findResultsCount}`
    ),
    findSpinner: searchBarElement.querySelector(
      `.${findBarClassNames.findSpinner}`
    )
  });
  pdfViewer.setFindController(findController);
  findController.onUpdateState = findBar.updateUIState.bind(findBar);
  findController.onUpdateResultsCount =
    findBar.updateResultsCount.bind(findBar);
}
function getGlobalToggleElementsForFindBar() {
  // using a universal selector here to avoid prop drilling or adding a new context containing reference to document actions
  // we might need to rework this if we experience issues with this
  // TODO: consider fixing this and pass it down/use context as everything else
  const documentAction = document.querySelector(
    `.${iconMenuClassNames.findToggle}`
  );

  return documentAction ? [documentAction] : [];
}
