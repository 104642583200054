/* eslint-disable complexity */
import * as React from "react";
import {
  DocumentFamilySubscriptionFormFieldProps,
  TypedForm
} from "./TypedForm";
import { Box, Grid, Typography } from "@material-ui/core";
import FormActions from "./FormActions";
import {
  SubscriptionFrequency,
  SubscriptionFrequencySelectFormField
} from "./SubscriptionFrequencySelectFormField";
import EmailSelectFormField from "./EmailSelectFormField";
import {
  DocumentFamilySubscriptionDto,
  DocumentSectionTreeDto
} from "@rhinestone/portal-web-api";
import { Accordion } from "../../ui-components/Accordion";
import DocumentSectionsTree from "./Sections/DocumentSectionsTree";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import SubscribeToEntireDocument from "./SubscribeToEntireDocumentCheckbox";
import { getTopLevelItems, isAllSectionsSelected } from "./Sections/helpers";
import { SubscriptionNameTextField } from "./SubscriptionNameTextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DocumentSectionChips from "./Sections/DocumentSectionsChips";

interface DocumentFamilySubscriptionFormProps {
  onCancel: () => void;
  onSubmit: (formData: DocumentFamilySubscriptionFormFieldProps) => void;
  data: DocumentFamilySubscriptionDto | undefined;
  userEmails?: string[];
  documentSectionTree: DocumentSectionTreeDto | undefined;
  defaultName: string;
}

export const DocumentFamilySubscriptionForm: React.FC<DocumentFamilySubscriptionFormProps> =
  ({
    onCancel,
    onSubmit,
    userEmails,
    data,
    documentSectionTree,
    defaultName
  }) => {
    const hasSectionSubscriptions = Boolean(
      data?.additionalFilters?.sectionTree.sections.length
    );
    const [subscribeToEntireDocument, setSubscribeToEntireDocument] =
      React.useState(!hasSectionSubscriptions);
    const [accordionExpanded, setAccordionExpanded] = React.useState(
      hasSectionSubscriptions
    );
    const [selectedSections, setSelectedSections] = React.useState<string[]>(
      data?.additionalFilters?.sectionTree.sections.map(s => s.sectionId) ?? []
    );

    const [selectedSectionTitles, setSelectedSectionsTitles] = React.useState<
      string[]
    >([]);

    React.useEffect(() => {
      const selectedItems = getTopLevelItems(
        documentSectionTree?.sections,
        selectedSections
      );
      setSelectedSectionsTitles(selectedItems.map(s => s.sectionTitle));
    }, [documentSectionTree?.sections, selectedSections]);

    const submitForm = (formData: DocumentFamilySubscriptionFormFieldProps) => {
      const allSectionsSelected = isAllSectionsSelected(
        documentSectionTree?.sections,
        selectedSections
      );
      const sections = allSectionsSelected
        ? []
        : getTopLevelItems(documentSectionTree?.sections, selectedSections);

      const formDataWithSelectedSections: DocumentFamilySubscriptionFormFieldProps =
        {
          ...formData,
          // If all nodes in the tree are selected we handle the subscription as if the user
          // subscribes to the entire document
          sections
        };
      onSubmit(formDataWithSelectedSections);
    };

    const toggleCheckbox = () => {
      setSubscribeToEntireDocument(prevExpandedState => !prevExpandedState);

      if (!accordionExpanded && subscribeToEntireDocument) {
        setAccordionExpanded(true);
      }
      if (accordionExpanded && !subscribeToEntireDocument) {
        setAccordionExpanded(false);
        setSelectedSections([]);
      }
    };
    const frequency = data?.frequency ?? SubscriptionFrequency.Daily;
    return (
      <TypedForm
        defaultValues={{
          email: data?.emailAddress ?? (userEmails ? userEmails[0] : ""),
          subscriptionName: data?.name ?? defaultName,
          frequency: frequency
        }}
        onSubmit={submitForm}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <EmailSelectFormField
              fieldName="email"
              disabled={!userEmails || userEmails.length <= 1}
              options={userEmails}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SubscriptionNameTextField fieldName="subscriptionName" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SubscriptionFrequencySelectFormField
              fieldName="frequency"
              defaultValue={frequency}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <SubscribeToEntireDocument
              isChecked={subscribeToEntireDocument}
              onChange={toggleCheckbox}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              label={
                <Typography variant="body1">
                  <Translate
                    id={
                      translationKeys.document_page
                        .document_family_subscription_form_document_sections
                    }
                  />
                </Typography>
              }
              expanded={accordionExpanded}
              onChange={() => setAccordionExpanded(!accordionExpanded)}
              disabled={subscribeToEntireDocument}
              expandIcon={<ExpandMoreIcon />}
            >
              <DocumentSectionChips titles={selectedSectionTitles} />
              <DocumentSectionsTree
                sectionTree={documentSectionTree}
                selectedSectionsIds={selectedSections}
                onSectionClick={sectionIds => setSelectedSections(sectionIds)}
              />
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid
            item
            container
            xs={12}
            md={12}
            justify="flex-end"
            direction="row"
          >
            <FormActions onCancel={onCancel} />
          </Grid>
        </Grid>
      </TypedForm>
    );
  };
