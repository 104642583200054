import React from "react";
import { connectToForm } from "./FormFieldWrapper";
import { BaseFormFieldProps } from "./FieldOptions";
import {
  ToggleButtonGroupBaseProps,
  ToggleButtonsGroup
} from "../../ToggleButtonGroup/ToggleButtonGroup";

export type FormToggleButtonGroupFieldProps<TFieldName extends string> =
  BaseFormFieldProps<TFieldName, string> & ToggleButtonGroupBaseProps;

const FormToggleButtonGroupField: <TFieldName extends string>(
  props: FormToggleButtonGroupFieldProps<TFieldName>
) => JSX.Element = connectToForm(ToggleButtonsGroup);

export default FormToggleButtonGroupField;
