export const translationKeys = {
  "account": {
    "contact_administrator": "account.contact_administrator",
    "enter_code": "account.enter_code",
    "error_could_not_log_in": "account.error_could_not_log_in",
    "error_organization_has_no_access": "account.error_organization_has_no_access",
    "logged_out": "account.logged_out",
    "profile_page_header": "account.profile_page_header",
    "my_profile_form_name_label": "account.my_profile_form_name_label",
    "my_profile_form_email_label": "account.my_profile_form_email_label",
    "my_profile_form_cancel": "account.my_profile_form_cancel",
    "my_profile_form_submit": "account.my_profile_form_submit",
    "my_profile_form_email_required": "account.my_profile_form_email_required",
    "my_profile_form_email_invalid": "account.my_profile_form_email_invalid",
    "my_profile_form_email_too_long": "account.my_profile_form_email_too_long",
    "my_profile_form_to_name_required": "account.my_profile_form_to_name_required",
    "my_profile_form_name_too_long": "account.my_profile_form_name_too_long",
    "email_required_header": "account.email_required_header",
    "email_required_message": "account.email_required_message"
  },
  "annotations": {
    "add_user_highlight": "annotations.add_user_highlight",
    "add_asset_collection_highlight": "annotations.add_asset_collection_highlight",
    "annotate_multiple_selection_message": "annotations.annotate_multiple_selection_message",
    "annotate_multiple_selection_title": "annotations.annotate_multiple_selection_title",
    "annotation_no_selection_dialog_message": "annotations.annotation_no_selection_dialog_message",
    "annotation_no_selection_dialog_title": "annotations.annotation_no_selection_dialog_title",
    "cancel": "annotations.cancel",
    "comment_not_deleted_error": "annotations.comment_not_deleted_error",
    "comment_not_saved_error": "annotations.comment_not_saved_error",
    "comments_in_document": "annotations.comments_in_document",
    "comments_not_placed_warning": "annotations.comments_not_placed_warning",
    "comments_disabled_tooltip": "annotations.comments_disabled_tooltip",
    "confirm_delete_message": "annotations.confirm_delete_message",
    "confirm_delete_title": "annotations.confirm_delete_title",
    "confirm_highlight_delete_message": "annotations.confirm_highlight_delete_message",
    "confirm_highlight_delete_title": "annotations.confirm_highlight_delete_title",
    "delete": "annotations.delete",
    "hide_annotations": "annotations.hide_annotations",
    "no_right_to_copy_comment_error": "annotations.no_right_to_copy_comment_error",
    "ok": "annotations.ok",
    "add_comment_success": "annotations.add_comment_success",
    "save_comment_success": "annotations.save_comment_success",
    "remove_comment_success": "annotations.remove_comment_success",
    "add_success": "annotations.add_success",
    "save_success": "annotations.save_success",
    "remove_success": "annotations.remove_success",
    "show_annotations": "annotations.show_annotations",
    "unable_to_load_comments": "annotations.unable_to_load_comments"
  },
  "assets": {
    "access": "assets.access",
    "access_permissions_all": "assets.access_permissions_all",
    "access_permissions_none": "assets.access_permissions_none",
    "access_permissions_read": "assets.access_permissions_read",
    "access_permissions_security": "assets.access_permissions_security",
    "access_permissions_write": "assets.access_permissions_write",
    "actions": "assets.actions",
    "active": "assets.active",
    "add": "assets.add",
    "add_heading": "assets.add_heading",
    "add_member_dialog_message": "assets.add_member_dialog_message",
    "add_member_dialog_title": "assets.add_member_dialog_title",
    "added_by": "assets.added_by",
    "anotation_type_selections": "assets.anotation_type_selections",
    "archive": "assets.archive",
    "archived": "assets.archived",
    "template_list": "assets.template_list",
    "archived_template_list": "assets.archived_template_list",
    "asset_added_success": "assets.asset_added_success",
    "asset_collection_comments": "assets.asset_collection_comments",
    "asset_collections": "assets.asset_collections",
    "asset_description": "assets.asset_description",
    "asset_front_page_text": "assets.asset_front_page_text",
    "asset_members_message": "assets.asset_members_message",
    "asset_pdf_message": "assets.asset_pdf_message",
    "asset_template_pdf_message": "assets.asset_template_pdf_message",
    "asset_pdf_title": "assets.asset_pdf_title",
    "asset_template_pdf_title": "assets.asset_template_pdf_title",
    "asset_releases_message": "assets.asset_releases_message",
    "asset_title": "assets.asset_title",
    "assets_added_success": "assets.assets_added_success",
    "assets_dialog_title": "assets.assets_dialog_title",
    "assets_view_title": "assets.assets_view_title",
    "author": "assets.author",
    "cancel": "assets.cancel",
    "click_here_to_move_node": "assets.click_here_to_move_node",
    "confirm_delete_collection_message": "assets.confirm_delete_collection_message",
    "confirm_delete_collection_title": "assets.confirm_delete_collection_title",
    "confirm_delete_node_dialog_message_1": "assets.confirm_delete_node_dialog_message_1",
    "confirm_delete_node_dialog_message_2": "assets.confirm_delete_node_dialog_message_2",
    "confirm_delete_node_dialog_message_3": "assets.confirm_delete_node_dialog_message_3",
    "confirm_delete_node_dialog_message_4": "assets.confirm_delete_node_dialog_message_4",
    "confirm_delete_node_dialog_message_5": "assets.confirm_delete_node_dialog_message_5",
    "contents": "assets.contents",
    "copy": "assets.copy",
    "copy_as_template": "assets.copy_as_template",
    "copy_as_template_successful": "assets.copy_as_template_successful",
    "copy_as_template_error_conflict": "assets.copy_as_template_error_conflict",
    "copy_from_template": "assets.copy_from_template",
    "copy_collection_dialog_message": "assets.copy_collection_dialog_message",
    "copy_collection_dialog_title": "assets.copy_collection_dialog_title",
    "copy_collection_as_template_dialog_message": "assets.copy_collection_as_template_dialog_message",
    "copy_collection_as_template_dialog_title": "assets.copy_collection_as_template_dialog_title",
    "copy_collection_from_template_dialog_title": "assets.copy_collection_from_template_dialog_title",
    "copy_collection_from_template_dialog_message": "assets.copy_collection_from_template_dialog_message",
    "copy_of": "assets.copy_of",
    "created": "assets.created",
    "delete_heading": "assets.delete_heading",
    "delete_item": "assets.delete_item",
    "detail_description_max_length_error": "assets.detail_description_max_length_error",
    "detail_dialog_message": "assets.detail_dialog_message",
    "detail_front_page_max_length_error": "assets.detail_front_page_max_length_error",
    "detail_max_length_error": "assets.detail_max_length_error",
    "detail_title_required_error": "assets.detail_title_required_error",
    "details": "assets.details",
    "doc_added_success": "assets.doc_added_success",
    "doc_not_available": "assets.doc_not_available",
    "docs_added_success": "assets.docs_added_success",
    "document_added": "assets.document_added",
    "documents_added": "assets.documents_added",
    "edit_heading": "assets.edit_heading",
    "error_message": "assets.error_message",
    "existing_collection_dialog_message": "assets.existing_collection_dialog_message",
    "existing_collection_dialog_title": "assets.existing_collection_dialog_title",
    "existing_collection_from_quote_dialog_message": "assets.existing_collection_from_quote_dialog_message",
    "existing_collection_from_quote_dialog_title": "assets.existing_collection_from_quote_dialog_title",
    "fetch": "assets.fetch",
    "go_to_asset_collections": "assets.go_to_asset_collections",
    "include_separator_pages": "assets.include_separator_pages",
    "is_archived": "assets.is_archived",
    "last_modified": "assets.last_modified",
    "move_graph_to_bottom": "assets.move_graph_to_bottom",
    "move_graph_to_top": "assets.move_graph_to_top",
    "my_comments": "assets.my_comments",
    "name": "assets.name",
    "name_required": "assets.name_required",
    "new_collection_from_quote_message": "assets.new_collection_from_quote_message",
    "new_collection_from_quote_title": "assets.new_collection_from_quote_title",
    "new_collection_message": "assets.new_collection_message",
    "new_collection_titile_length_error": "assets.new_collection_titile_length_error",
    "new_collection_title": "assets.new_collection_title",
    "new_collection_title_pattern_error": "assets.new_collection_title_pattern_error",
    "new_folder": "assets.new_folder",
    "no_results": "assets.no_results",
    "no_selection_dialog_message": "assets.no_selection_dialog_message",
    "no_selection_dialog_title": "assets.no_selection_dialog_title",
    "node_deleted_success": "assets.node_deleted_success",
    "ok": "assets.ok",
    "only_pdf_accepted": "assets.only_pdf_accepted",
    "owner": "assets.owner",
    "permissions": "assets.permissions",
    "publication": "assets.publication",
    "publish": "assets.publish",
    "publish_asset_message": "assets.publish_asset_message",
    "publish_asset_title": "assets.publish_asset_title",
    "publish_error": "assets.publish_error",
    "publish_error_message": "assets.publish_error_message",
    "publish_error_title": "assets.publish_error_title",
    "publish_success": "assets.publish_success",
    "publisher": "assets.publisher",
    "reference_number": "assets.reference_number",
    "release_complete_success": "assets.release_complete_success",
    "releases": "assets.releases",
    "remove": "assets.remove",
    "requested_at": "assets.requested_at",
    "requested_by": "assets.requested_by",
    "save": "assets.save",
    "saved_success": "assets.saved_success",
    "saving_message": "assets.saving_message",
    "search": "assets.search",
    "select_file": "assets.select_file",
    "share_comments_permission": "assets.share_comments_permission",
    "showing_first_n_users": "assets.showing_first_n_users",
    "template": "assets.template",
    "title": "assets.title",
    "tree_view_decrease": "assets.tree_view_decrease",
    "tree_view_decrease_access_key": "assets.tree_view_decrease_access_key",
    "tree_view_decrease_caption": "assets.tree_view_decrease_caption",
    "tree_view_down": "assets.tree_view_down",
    "tree_view_down_access_key": "assets.tree_view_down_access_key",
    "tree_view_down_caption": "assets.tree_view_down_caption",
    "tree_view_edit": "assets.tree_view_edit",
    "tree_view_edit_access_key": "assets.tree_view_edit_access_key",
    "tree_view_edit_caption": "assets.tree_view_edit_caption",
    "tree_view_increase": "assets.tree_view_increase",
    "tree_view_increase_access_key": "assets.tree_view_increase_access_key",
    "tree_view_increase_caption": "assets.tree_view_increase_caption",
    "tree_view_message": "assets.tree_view_message",
    "tree_view_move": "assets.tree_view_move",
    "tree_view_move_access_key": "assets.tree_view_move_access_key",
    "tree_view_move_caption": "assets.tree_view_move_caption",
    "tree_view_remove": "assets.tree_view_remove",
    "tree_view_remove_access_key": "assets.tree_view_remove_access_key",
    "tree_view_remove_caption": "assets.tree_view_remove_caption",
    "tree_view_up": "assets.tree_view_up",
    "tree_view_up_access_key": "assets.tree_view_up_access_key",
    "tree_view_up_caption": "assets.tree_view_up_caption",
    "upload_failed": "assets.upload_failed",
    "upload_file": "assets.upload_file",
    "upload_file_dialog_title": "assets.upload_file_dialog_title",
    "uploaded_file_too_large": "assets.uploaded_file_too_large",
    "user_added": "assets.user_added",
    "user_removed": "assets.user_removed",
    "users": "assets.users",
    "users_added": "assets.users_added",
    "with_comment": "assets.with_comment",
    "without_comment": "assets.without_comment"
  },
  "boolean": {
    "false": "boolean.false",
    "true": "boolean.true"
  },
  "date_picker": {
    "from": "date_picker.from",
    "invalid_date": "date_picker.invalid_date",
    "invalid_max_date": "date_picker.invalid_max_date",
    "invalid_min_date": "date_picker.invalid_min_date",
    "to": "date_picker.to",
    "end_date_picker_label": "date_picker.end_date_picker_label"
  },
  "document_metadata": {
    "abbreviation": "document_metadata.abbreviation",
    "abstract": "document_metadata.abstract",
    "additional_title": "document_metadata.additional_title",
    "afg": "document_metadata.afg",
    "afgoerelsestype": "document_metadata.afgoerelsestype",
    "Afgoerelsestype": "document_metadata.Afgoerelsestype",
    "anden_part": "document_metadata.anden_part",
    "Ansvarlig": "document_metadata.Ansvarlig",
    "anvendelse": "document_metadata.anvendelse",
    "Anvendelse": "document_metadata.Anvendelse",
    "application": "document_metadata.application",
    "Arbeidsstatus": "document_metadata.Arbeidsstatus",
    "areas": "document_metadata.areas",
    "ast_jnr": "document_metadata.ast_jnr",
    "author": "document_metadata.author",
    "bedrageritype": "document_metadata.bedrageritype",
    "beloeb": "document_metadata.beloeb",
    "bemarkninger": "document_metadata.bemarkninger",
    "book_title": "document_metadata.book_title",
    "case_number": "document_metadata.case_number",
    "casenumber": "document_metadata.casenumber",
    "category": "document_metadata.category",
    "copyright": "document_metadata.copyright",
    "court": "document_metadata.court",
    "danish_law_preparatory_work_number": "document_metadata.danish_law_preparatory_work_number",
    "date_adopted": "document_metadata.date_adopted",
    "date_approved": "document_metadata.date_approved",
    "date_created": "document_metadata.date_created",
    "date_decided": "document_metadata.date_decided",
    "date_decision": "document_metadata.date_decision",
    "date_effect": "document_metadata.date_effect",
    "date_expired": "document_metadata.date_expired",
    "date_issued": "document_metadata.date_issued",
    "date_judged": "document_metadata.date_judged",
    "date_modified": "document_metadata.date_modified",
    "date_passed": "document_metadata.date_passed",
    "date_pronounced": "document_metadata.date_pronounced",
    "date_release": "document_metadata.date_release",
    "date_sorting": "document_metadata.date_sorting",
    "date_submitted": "document_metadata.date_submitted",
    "date_written": "document_metadata.date_written",
    "dato": "document_metadata.dato",
    "Dato": "document_metadata.Dato",
    "decision_type": "document_metadata.decision_type",
    "definitely_decided": "document_metadata.definitely_decided",
    "doc_number": "document_metadata.doc_number",
    "docdate": "document_metadata.docdate",
    "document_type": "document_metadata.document_type",
    "Dokument_hos": "document_metadata.Dokument_hos",
    "dommere": "document_metadata.dommere",
    "Dommere": "document_metadata.Dommere",
    "edition": "document_metadata.edition",
    "effective_lbk_number": "document_metadata.effective_lbk_number",
    "eisbn": "document_metadata.eisbn",
    "emne": "document_metadata.emne",
    "emnelongtitle": "document_metadata.emnelongtitle",
    "Endrer": "document_metadata.Endrer",
    "erstattet": "document_metadata.erstattet",
    "fagsystem": "document_metadata.fagsystem",
    "family_status": "document_metadata.family_status",
    "field_resume": "document_metadata.field_resume",
    "file_name": "document_metadata.file_name",
    "forfattere": "document_metadata.forfattere",
    "Forfattere": "document_metadata.Forfattere",
    "forsikringstype": "document_metadata.forsikringstype",
    "forstraf": "document_metadata.forstraf",
    "ft_assembly": "document_metadata.ft_assembly",
    "hjemmel": "document_metadata.hjemmel",
    "Hjemmel": "document_metadata.Hjemmel",
    "hovedbeskyttelse": "document_metadata.hovedbeskyttelse",
    "instans": "document_metadata.instans",
    "Instans": "document_metadata.Instans",
    "isbn": "document_metadata.isbn",
    "issuer": "document_metadata.issuer",
    "journal_number": "document_metadata.journal_number",
    "journalcitation": "document_metadata.journalcitation",
    "journalnr": "document_metadata.journalnr",
    "katagorisort": "document_metadata.katagorisort",
    "keywords": "document_metadata.keywords",
    "kfenr": "document_metadata.kfenr",
    "kilde": "document_metadata.kilde",
    "kildetitle": "document_metadata.kildetitle",
    "kind": "document_metadata.kind",
    "kommission": "document_metadata.kommission",
    "kommune": "document_metadata.kommune",
    "kriminalitet": "document_metadata.kriminalitet",
    "kropsdel": "document_metadata.kropsdel",
    "lbk_date": "document_metadata.lbk_date",
    "lbk_latest_date": "document_metadata.lbk_latest_date",
    "lbk_latest": "document_metadata.lbk_latest",
    "lbk_nr": "document_metadata.lbk_nr",
    "legal_area": "document_metadata.legal_area",
    "legislate": "document_metadata.legislate",
    "local_document_type": "document_metadata.local_document_type",
    "lov": "document_metadata.lov",
    "lovgivning": "document_metadata.lovgivning",
    "lovgrundlag": "document_metadata.lovgrundlag",
    "lovligtophold": "document_metadata.lovligtophold",
    "malgruppe": "document_metadata.malgruppe",
    "ministry": "document_metadata.ministry",
    "minkstatus": "document_metadata.minkstatus",
    "modpart": "document_metadata.modpart",
    "name": "document_metadata.name",
    "Navn": "document_metadata.Navn",
    "nickname": "document_metadata.nickname",
    "number": "document_metadata.number",
    "off_tbb_dato": "document_metadata.off_tbb_dato",
    "overgaaet": "document_metadata.overgaaet",
    "part": "document_metadata.part",
    "parter": "document_metadata.parter",
    "Parter": "document_metadata.Parter",
    "penalty": "document_metadata.penalty",
    "position": "document_metadata.position",
    "presented_by": "document_metadata.presented_by",
    "previous_penalty": "document_metadata.previous_penalty",
    "principiel_dom": "document_metadata.principiel_dom",
    "printed": "document_metadata.printed",
    "pronounced_by": "document_metadata.pronounced_by",
    "publicly_available": "document_metadata.publicly_available",
    "referencer": "document_metadata.referencer",
    "related_dom": "document_metadata.related_dom",
    "relevans": "document_metadata.relevans",
    "remarks": "document_metadata.remarks",
    "responsible_group": "document_metadata.responsible_group",
    "responsible": "document_metadata.responsible",
    "resume": "document_metadata.resume",
    "retsgrundlag": "document_metadata.retsgrundlag",
    "sagsnr": "document_metadata.sagsnr",
    "saksgang": "document_metadata.saksgang",
    "Saksgang": "document_metadata.Saksgang",
    "sendt_til": "document_metadata.sendt_til",
    "Sendt_til": "document_metadata.Sendt_til",
    "shorttitle": "document_metadata.shorttitle",
    "sist_endret_av": "document_metadata.sist_endret_av",
    "Sist_endret_av": "document_metadata.Sist_endret_av",
    "slr_number": "document_metadata.slr_number",
    "source": "document_metadata.source",
    "status": "document_metadata.status",
    "stikord": "document_metadata.stikord",
    "Stikord": "document_metadata.Stikord",
    "stoffetsart": "document_metadata.stoffetsart",
    "stofmaengde": "document_metadata.stofmaengde",
    "straf": "document_metadata.straf",
    "straffen": "document_metadata.straffen",
    "straflaengde": "document_metadata.straflaengde",
    "subjects": "document_metadata.subjects",
    "substance_amount": "document_metadata.substance_amount",
    "sygdom": "document_metadata.sygdom",
    "tag": "document_metadata.tag",
    "tags": "document_metadata.tags",
    "tbbnr": "document_metadata.tbbnr",
    "tidligerestraffet": "document_metadata.tidligerestraffet",
    "Titel": "document_metadata.Titel",
    "titelsort": "document_metadata.titelsort",
    "title": "document_metadata.title",
    "titlesort": "document_metadata.titlesort",
    "topic_product": "document_metadata.topic_product",
    "topic_serviceguide": "document_metadata.topic_serviceguide",
    "translation_status": "document_metadata.translation_status",
    "trst_casenumber": "document_metadata.trst_casenumber",
    "trst_createdby": "document_metadata.trst_createdby",
    "trst_datecreated": "document_metadata.trst_datecreated",
    "trst_dateeffect": "document_metadata.trst_dateeffect",
    "trst_dateexpired": "document_metadata.trst_dateexpired",
    "trst_dateupdated": "document_metadata.trst_dateupdated",
    "trst_keywords": "document_metadata.trst_keywords",
    "trst_kommission": "document_metadata.trst_kommission",
    "trst_offentlig": "document_metadata.trst_offentlig",
    "trst_referral": "document_metadata.trst_referral",
    "trst_tags": "document_metadata.trst_tags",
    "trst_updatedby": "document_metadata.trst_updatedby",
    "trykt": "document_metadata.trykt",
    "Trykt": "document_metadata.Trykt",
    "type_number_date_release": "document_metadata.type_number_date_release",
    "type": "document_metadata.type",
    "udfald": "document_metadata.udfald",
    "ulykke": "document_metadata.ulykke",
    "under_anke": "document_metadata.under_anke",
    "virksomhedstype": "document_metadata.virksomhedstype",
    "voldgift_sammen": "document_metadata.voldgift_sammen"
  },
  "document_page": {
    "field_empty": "document_page.field_empty",
    "about_danish_law": "document_page.about_danish_law",
    "about_document": "document_page.about_document",
    "about_document_family": "document_page.about_document_family",
    "attachments_section_header": "document_page.attachments_section_header",
    "preparatory_work": "document_page.preparatory_work",
    "select_timeline_path": "document_page.select_timeline_path",
    "add_document_to_asset_collection": "document_page.add_document_to_asset_collection",
    "and_opened_locally": "document_page.and_opened_locally",
    "asset_colletion": "document_page.asset_colletion",
    "back_to_document": "document_page.back_to_document",
    "back_to_main_page": "document_page.back_to_main_page",
    "breadcrumb": "document_page.breadcrumb",
    "cancel": "document_page.cancel",
    "case_number": "document_page.case_number",
    "changes": "document_page.changes",
    "collapse_all": "document_page.collapse_all",
    "comment": "document_page.comment",
    "comment_not_saved_error": "document_page.comment_not_saved_error",
    "comments_not_allowed_in_document": "document_page.comments_not_allowed_in_document",
    "consolidated_by": "document_page.consolidated_by",
    "consolidated_by_not_found": "document_page.consolidated_by_not_found",
    "consolidated_by_aria_label": "document_page.consolidated_by_aria_label",
    "copy": "document_page.copy",
    "copy_to": "document_page.copy_to",
    "create_link_dialog_period_title": "document_page.create_link_dialog_period_title",
    "create_link_dialog_version_title": "document_page.create_link_dialog_version_title",
    "create_link_dialog_version_actual": "document_page.create_link_dialog_version_actual",
    "create_link_dialog_version_newest": "document_page.create_link_dialog_version_newest",
    "create_link_version_required": "document_page.create_link_version_required",
    "create_link_dialog_commit": "document_page.create_link_dialog_commit",
    "create_link_period_required": "document_page.create_link_period_required",
    "create_link_period_min": "document_page.create_link_period_min",
    "create_link_period_max": "document_page.create_link_period_max",
    "create_link_period_year_label": "document_page.create_link_period_year_label",
    "create_link_period_month_label": "document_page.create_link_period_month_label",
    "create_link_period_day_label": "document_page.create_link_period_day_label",
    "current": "document_page.current",
    "date": "document_page.date",
    "decision_date": "document_page.decision_date",
    "delete": "document_page.delete",
    "delete_annotation_error": "document_page.delete_annotation_error",
    "direct_source_documents": "document_page.direct_source_documents",
    "direct_target_documents": "document_page.direct_target_documents",
    "document": "document_page.document",
    "document_can": "document_page.document_can",
    "document_family_subscription_button_label": "document_page.document_family_subscription_button_label",
    "document_family_subscription_dialog_title": "document_page.document_family_subscription_dialog_title",
    "document_family_subscription_frequency_daily": "document_page.document_family_subscription_frequency_daily",
    "document_family_subscription_frequency_weekly": "document_page.document_family_subscription_frequency_weekly",
    "document_family_subscription_frequency_monthly": "document_page.document_family_subscription_frequency_monthly",
    "document_family_subscription_form_cancel_label": "document_page.document_family_subscription_form_cancel_label",
    "document_family_subscription_form_submit_label": "document_page.document_family_subscription_form_submit_label",
    "document_family_subscription_form_name_label": "document_page.document_family_subscription_form_name_label",
    "document_family_subscription_form_name_required": "document_page.document_family_subscription_form_name_required",
    "document_family_subscription_form_name_length": "document_page.document_family_subscription_form_name_length",
    "document_family_subscription_form_email_label": "document_page.document_family_subscription_form_email_label",
    "document_family_subscription_form_email_required": "document_page.document_family_subscription_form_email_required",
    "document_family_subscription_form_success": "document_page.document_family_subscription_form_success",
    "document_family_subscription_form_subscribe_to_entire_document": "document_page.document_family_subscription_form_subscribe_to_entire_document",
    "document_family_subscription_form_document_sections": "document_page.document_family_subscription_form_document_sections",
    "document_family_subscription_form_document_has_no_sections": "document_page.document_family_subscription_form_document_has_no_sections",
    "document_family_subscription_info_header": "document_page.document_family_subscription_info_header",
    "document_family_subscription_info_text": "document_page.document_family_subscription_info_text",
    "document_in_asset_collection_context": "document_page.document_in_asset_collection_context",
    "document_in_asset_collection_template_context": "document_page.document_in_asset_collection_template_context",
    "document_lc": "document_page.document_lc",
    "document_not_found_message": "document_page.document_not_found_message",
    "document_not_found_title": "document_page.document_not_found_title",
    "document_relations_load_error": "document_page.document_relations_load_error",
    "document_search_button": "document_page.document_search_button",
    "document_search_related_documents": "document_page.document_search_related_documents",
    "document_section": "document_page.document_section",
    "document_show_all_relations": "document_page.document_show_all_relations",
    "document_show_n_relations": "document_page.document_show_n_relations",
    "document_show_relation": "document_page.document_show_relation",
    "document_timeline_split_label": "document_page.document_timeline_split_label",
    "document_timeline_merge_label": "document_page.document_timeline_merge_label",
    "document_timeline_latest_effective": "document_page.document_timeline_latest_effective",
    "document_timeline_effective_lbk": "document_page.document_timeline_effective_lbk",
    "document_type": "document_page.document_type",
    "document_version_subscription_create_error": "document_page.document_version_subscription_create_error",
    "document_version_subscription_created": "document_page.document_version_subscription_created",
    "document_version_subscription_enter_name": "document_page.document_version_subscription_enter_name",
    "document_version_subscription_name": "document_page.document_version_subscription_name",
    "document_version_subscription_remove_error": "document_page.document_version_subscription_remove_error",
    "document_version_subscription_removed": "document_page.document_version_subscription_removed",
    "document_view_asset_collection": "document_page.document_view_asset_collection",
    "documentactions_title": "document_page.documentactions_title",
    "download_as_pdf": "document_page.download_as_pdf",
    "downloaded": "document_page.downloaded",
    "effective_date": "document_page.effective_date",
    "enter_comment": "document_page.enter_comment",
    "error_occured": "document_page.error_occured",
    "expand_all": "document_page.expand_all",
    "export": "document_page.export",
    "export_dialog_title": "document_page.export_dialog_title",
    "export_document_sections_dialog_title": "document_page.export_document_sections_dialog_title",
    "export_document_sections": "document_page.export_document_sections",
    "export_pdf_error_message": "document_page.export_pdf_error_message",
    "export_print_toc": "document_page.export_print_toc",
    "export_print_cover": "document_page.export_print_cover",
    "export_with": "document_page.export_with",
    "export_show_abstract": "document_page.export_show_abstract",
    "export_show_comments": "document_page.export_show_comments",
    "export_with_annotations": "document_page.export_with_annotations",
    "field_abstract": "document_page.field_abstract",
    "field_docheader": "document_page.field_docheader",
    "generate_document_link": "document_page.generate_document_link",
    "generate_document_link_error": "document_page.generate_document_link_error",
    "hide_large_document_view": "document_page.hide_large_document_view",
    "history": "document_page.history",
    "indirect_target_documents": "document_page.indirect_target_documents",
    "informal_name": "document_page.informal_name",
    "instance": "document_page.instance",
    "image_not_found": "document_page.image_not_found",
    "issue_date": "document_page.issue_date",
    "issued_by": "document_page.issued_by",
    "journal_number": "document_page.journal_number",
    "last_edited_by": "document_page.last_edited_by",
    "last_updated_by": "document_page.last_updated_by",
    "later_documents": "document_page.later_documents",
    "lbk": "document_page.lbk",
    "latest_lbk_tooltip": "document_page.latest_lbk_tooltip",
    "loading": "document_page.loading",
    "loading_relations": "document_page.loading_relations",
    "my_asset_collection": "document_page.my_asset_collection",
    "no_document_type": "document_page.no_document_type",
    "no_content": "document_page.no_content",
    "no_section_relations": "document_page.no_section_relations",
    "notes": "document_page.notes",
    "number": "document_page.number",
    "open_export_document_parts_dialog": "document_page.open_export_document_parts_dialog",
    "original_comment_location": "document_page.original_comment_location",
    "page": "document_page.page",
    "paragraph_zoom": "document_page.paragraph_zoom",
    "pdf": "document_page.pdf",
    "print": "document_page.print",
    "print_title": "document_page.print_title",
    "product_membership": "document_page.product_membership",
    "quick_navigation_left": "document_page.quick_navigation_left",
    "quick_navigation_left_title": "document_page.quick_navigation_left_title",
    "quick_navigation_main": "document_page.quick_navigation_main",
    "quick_navigation_main_title": "document_page.quick_navigation_main_title",
    "quick_navigation_right": "document_page.quick_navigation_right",
    "quick_navigation_right_title": "document_page.quick_navigation_right_title",
    "references_this_section": "document_page.references_this_section",
    "relation": "document_page.relation",
    "relation_could_not_be_loaded": "document_page.relation_could_not_be_loaded",
    "relation_page": "document_page.relation_page",
    "relation_to": "document_page.relation_to",
    "relations_none": "document_page.relations_none",
    "relations_with_historic_documents": "document_page.relations_with_historic_documents",
    "relations_with_outstanding_documents": "document_page.relations_with_outstanding_documents",
    "relations_not_grouped": "document_page.relations_not_grouped",
    "repeal_date": "document_page.repeal_date",
    "revision_date": "document_page.revision_date",
    "save": "document_page.save",
    "select_all": "document_page.select_all",
    "send_document_link": "document_page.send_document_link",
    "send_document_link_dialog_from_email": "document_page.send_document_link_dialog_from_email",
    "send_document_link_dialog_from_name": "document_page.send_document_link_dialog_from_name",
    "send_document_link_dialog_from_title": "document_page.send_document_link_dialog_from_title",
    "send_document_link_dialog_link": "document_page.send_document_link_dialog_link",
    "send_document_link_dialog_message": "document_page.send_document_link_dialog_message",
    "send_document_link_dialog_send": "document_page.send_document_link_dialog_send",
    "send_document_link_dialog_generate_ticket": "document_page.send_document_link_dialog_generate_ticket",
    "send_document_link_dialog_cancel": "document_page.send_document_link_dialog_cancel",
    "send_document_link_dialog_title": "document_page.send_document_link_dialog_title",
    "send_document_link_dialog_to_email": "document_page.send_document_link_dialog_to_email",
    "send_document_link_dialog_to_name": "document_page.send_document_link_dialog_to_name",
    "send_document_link_dialog_to_title": "document_page.send_document_link_dialog_to_title",
    "send_document_link_name_too_long": "document_page.send_document_link_name_too_long",
    "send_document_link_to_name_required": "document_page.send_document_link_to_name_required",
    "send_document_link_to_email_required": "document_page.send_document_link_to_email_required",
    "send_document_link_to_email_invalid": "document_page.send_document_link_to_email_invalid",
    "send_document_link_to_email_too_long": "document_page.send_document_link_to_email_too_long",
    "send_document_link_message_required": "document_page.send_document_link_message_required",
    "send_document_link_message_too_long": "document_page.send_document_link_message_too_long",
    "send_document_link_effective": "document_page.send_document_link_effective",
    "send_document_link_exact": "document_page.send_document_link_exact",
    "send_document_link_expiration_period": "document_page.send_document_link_expiration_period",
    "send_document_link_expiration_period_days": "document_page.send_document_link_expiration_period_days",
    "send_document_link_expiration_period_months": "document_page.send_document_link_expiration_period_months",
    "send_document_link_expiration_period_years": "document_page.send_document_link_expiration_period_years",
    "send_document_link_success": "document_page.send_document_link_success",
    "send_document_link_dialog_link_copy": "document_page.send_document_link_dialog_link_copy",
    "send_document_link_dialog_link_copy_success": "document_page.send_document_link_dialog_link_copy_success",
    "show_changes": "document_page.show_changes",
    "show_less": "document_page.show_less",
    "show_more": "document_page.show_more",
    "show_large_document_view": "document_page.show_large_document_view",
    "source": "document_page.source",
    "status": "document_page.status",
    "subscription_create": "document_page.subscription_create",
    "subscription_remove": "document_page.subscription_remove",
    "table_of_contents": "document_page.table_of_contents",
    "document_history_timeline": "document_page.document_history_timeline",
    "search_in_table_of_contents": "document_page.search_in_table_of_contents",
    "title": "document_page.title",
    "toggle_sections": "document_page.toggle_sections",
    "date_undecided": "document_page.date_undecided",
    "ufr_number": "document_page.ufr_number",
    "update_annotation_error": "document_page.update_annotation_error",
    "view_history": "document_page.view_history",
    "view_notes": "document_page.view_notes",
    "watermark_historic": "document_page.watermark_historic",
    "watermark_pending": "document_page.watermark_pending",
    "watermark_appealed": "document_page.watermark_appealed",
    "watermark_latest_lbk": "document_page.watermark_latest_lbk",
    "watermark_previous_version": "document_page.watermark_previous_version",
    "with_comments": "document_page.with_comments",
    "without_comments": "document_page.without_comments"
  },
  "law_zoom": {
    "title": "law_zoom.title",
    "changed_sections": "law_zoom.changed_sections"
  },
  "paragraph_zoom": {
    "status": {
      "effective": "paragraph_zoom.status.effective",
      "historic": "paragraph_zoom.status.historic",
      "coming": "paragraph_zoom.status.coming"
    },
    "not_found": {
      "title": "paragraph_zoom.not_found.title",
      "message": "paragraph_zoom.not_found.message"
    },
    "go_to_lff": "paragraph_zoom.go_to_lff",
    "unknown_error": "paragraph_zoom.unknown_error",
    "bill_text_tab_title": "paragraph_zoom.bill_text_tab_title",
    "change_label": "paragraph_zoom.change_label",
    "consolidated": "paragraph_zoom.consolidated",
    "general_remarks_tab_title": "paragraph_zoom.general_remarks_tab_title",
    "repealed": "paragraph_zoom.repealed",
    "special_remarks_tab_title": "paragraph_zoom.special_remarks_tab_title",
    "tabs_no_data_title": "paragraph_zoom.tabs_no_data_title",
    "tabs_no_data_contents": "paragraph_zoom.tabs_no_data_contents",
    "bill_text_header_link_text": "paragraph_zoom.bill_text_header_link_text",
    "general_remarks_header_link_text": "paragraph_zoom.general_remarks_header_link_text",
    "special_remarks_header_link_text": "paragraph_zoom.special_remarks_header_link_text",
    "tab_section_no_data_title": "paragraph_zoom.tab_section_no_data_title"
  },
  "error_pages": {
    "back_to_main_page": "error_pages.back_to_main_page",
    "contact_administrator": "error_pages.contact_administrator",
    "document_not_found": "error_pages.document_not_found",
    "internal_error": "error_pages.internal_error",
    "page_not_found": "error_pages.page_not_found",
    "provided_ticket_not_valid": "error_pages.provided_ticket_not_valid",
    "unauthorized_access": "error_pages.unauthorized_access"
  },
  "relation_groups": {
    "close": "relation_groups.close",
    "relation_group_not_found": "relation_groups.relation_group_not_found"
  },
  "find_in_document": {
    "close": "find_in_document.close",
    "next": "find_in_document.next",
    "previous": "find_in_document.previous",
    "search_term": "find_in_document.search_term"
  },
  "layout": {
    "login_dialog_button_continue_without": "layout.login_dialog_button_continue_without",
    "login_dialog_button_login": "layout.login_dialog_button_login",
    "login_dialog_message": "layout.login_dialog_message",
    "login_dialog_title": "layout.login_dialog_title",
    "quick_navigation_main": "layout.quick_navigation_main",
    "quick_navigation_main_title": "layout.quick_navigation_main_title"
  },
  "locale": {
    "nb": "locale.nb",
    "nn": "locale.nn"
  },
  "main": {
    "opens_in_new_window": "main.opens_in_new_window",
    "quick_navigation": "main.quick_navigation"
  },
  "product_page": {
    "title": "product_page.title"
  },
  "ribbon": {
    "add_to_asset_collection": "ribbon.add_to_asset_collection",
    "add_to_existing_asset_collection": "ribbon.add_to_existing_asset_collection",
    "add_to_new_asset_collection": "ribbon.add_to_new_asset_collection",
    "asset_collections": "ribbon.asset_collections",
    "chat": "ribbon.chat",
    "go_to_main_page": "ribbon.go_to_main_page",
    "help": "ribbon.help",
    "log_in": "ribbon.log_in",
    "my_news_subscriptions": "ribbon.my_news_subscriptions",
    "my_profile": "ribbon.my_profile",
    "products": "ribbon.products",
    "search": "ribbon.search",
    "ai_assistant": "ribbon.ai_assistant",
    "select_text": "ribbon.select_text",
    "show": "ribbon.show",
    "show_comments": "ribbon.show_comments",
    "sign_out": "ribbon.sign_out",
    "toggle_navigation": "ribbon.toggle_navigation",
    "toggle_sidepanes": "ribbon.toggle_sidepanes"
  },
  "search": {
    "add_new_search": "search.add_new_search",
    "add_success": "search.add_success",
    "add_to": "search.add_to",
    "add_to_existing_material_collection_label": "search.add_to_existing_material_collection_label",
    "add_to_new_material_collection_label": "search.add_to_new_material_collection_label",
    "all_products": "search.all_products",
    "cancel": "search.cancel",
    "create_subscription": "search.create_subscription",
    "custom_filters_drugs": "search.custom_filters_drugs",
    "custom_filters_fraud": "search.custom_filters_fraud",
    "custom_filters_title": "search.custom_filters_title",
    "custom_filters_deportation": "search.custom_filters_deportation",
    "date": "search.date",
    "date_from": "search.date_from",
    "date_to": "search.date_to",
    "delete": "search.delete",
    "delete_saved_search_message": "search.delete_saved_search_message",
    "delete_saved_search_title": "search.delete_saved_search_title",
    "document_type": "search.document_type",
    "document_types": "search.document_types",
    "document_version_subscription": "search.document_version_subscription",
    "document_family_subscription": "search.document_family_subscription",
    "documents_suggestions": "search.documents_suggestions",
    "enter_name_error_message": "search.enter_name_error_message",
    "enter_name_help": "search.enter_name_help",
    "enter_name_label": "search.enter_name_label",
    "export_to_pdf": "search.export_to_pdf",
    "fetch": "search.fetch",
    "file": "search.file",
    "filters": "search.filters",
    "get_search": "search.get_search",
    "include_in": "search.include_in",
    "instance": "search.instance",
    "slr_subject": "search.slr_subject",
    "issue_date": "search.issue_date",
    "labor_law": "search.labor_law",
    "loader_message": "search.loader_message",
    "local_document_type": "search.local_document_type",
    "last_visit": "search.last_visit",
    "material_collections": "search.material_collections",
    "name": "search.name",
    "new_documents": "search.new_documents",
    "newsletter": "search.newsletter",
    "nickname": "search.nickname",
    "no_products": "search.no_products",
    "no_results": "search.no_results",
    "no_saved_searches": "search.no_saved_searches",
    "no_taxonomies": "search.no_taxonomies",
    "no_taxonomies_depend_on_criteria": "search.no_taxonomies_depend_on_criteria",
    "number": "search.number",
    "order_by": "search.order_by",
    "overwrite": "search.overwrite",
    "overwrite_warning_message": "search.overwrite_warning_message",
    "pager_text": "search.pager_text",
    "pager_text_mobile": "search.pager_text_mobile",
    "pdf_create_error": "search.pdf_create_error",
    "pdf_file_name": "search.pdf_file_name",
    "print_search_results": "search.print_search_results",
    "product": "search.product",
    "product_affiliation": "search.product_affiliation",
    "product_selector": "search.product_selector",
    "published_in": "search.published_in",
    "quick_navigation_left": "search.quick_navigation_left",
    "quick_navigation_left_title": "search.quick_navigation_left_title",
    "quick_navigation_main": "search.quick_navigation_main",
    "quick_navigation_main_title": "search.quick_navigation_main_title",
    "quick_navigation_right": "search.quick_navigation_right",
    "all_related_documents": "search.all_related_documents",
    "bookmark_related_documents": "search.bookmark_related_documents",
    "no_bookmark_related_documents": "search.no_bookmark_related_documents",
    "quick_navigation_right_title": "search.quick_navigation_right_title",
    "relevance": "search.relevance",
    "remove": "search.remove",
    "remove_error": "search.remove_error",
    "remove_success": "search.remove_success",
    "reset": "search.reset",
    "reset_chip": "search.reset_chip",
    "results": "search.results",
    "results_sorting": "search.results_sorting",
    "reverse_ordering": "search.reverse_ordering",
    "revision_date": "search.revision_date",
    "save": "search.save",
    "save_search": "search.save_search",
    "saved_search_exists": "search.saved_search_exists",
    "saved_search_saving_error": "search.saved_search_saving_error",
    "saved_searches": "search.saved_searches",
    "saved_searches_section_name": "search.saved_searches_section_name",
    "search": "search.search",
    "search_delimitation": "search.search_delimitation",
    "search_in": "search.search_in",
    "search_in_karnov": "search.search_in_karnov",
    "search_in_repeat": "search.search_in_repeat",
    "search_in_state_attorney_library": "search.search_in_state_attorney_library",
    "search_results": "search.search_results",
    "search_results_export_limit_exceeded": "search.search_results_export_limit_exceeded",
    "search_results_navigation": "search.search_results_navigation",
    "search_preview_title": "search.search_preview_title",
    "search_preview_text": "search.search_preview_text",
    "search_preview_close": "search.search_preview_close",
    "search_preview_validation_error": "search.search_preview_validation_error",
    "search_subscription": "search.search_subscription",
    "search_taxons": "search.search_taxons",
    "search_title": "search.search_title",
    "search_word": "search.search_word",
    "searchfiltergroupheader_period": "search.searchfiltergroupheader_period",
    "searchfiltergroupheader_status": "search.searchfiltergroupheader_status",
    "show_all_slices": "search.show_all_slices",
    "show_one_slice": "search.show_one_slice",
    "sort_ascending": "search.sort_ascending",
    "sort_by": "search.sort_by",
    "sort_descending": "search.sort_descending",
    "status": "search.status",
    "taxonomies": "search.taxonomies",
    "taxonomy": "search.taxonomy",
    "title": "search.title",
    "update_error": "search.update_error",
    "update_success": "search.update_success",
    "views": "search.views",
    "warning": "search.warning",
    "words_in_documents": "search.words_in_documents"
  },
  "search_subscriptions": {
    "email_conflict": "search_subscriptions.email_conflict",
    "active": "search_subscriptions.active",
    "all_news": "search_subscriptions.all_news",
    "cancel": "search_subscriptions.cancel",
    "confirm_email": "search_subscriptions.confirm_email",
    "confirmation_greeting": "search_subscriptions.confirmation_greeting",
    "confirmation_missing": "search_subscriptions.confirmation_missing",
    "create_error": "search_subscriptions.create_error",
    "create_subscription": "search_subscriptions.create_subscription",
    "create_success": "search_subscriptions.create_success",
    "daily": "search_subscriptions.daily",
    "daily_subscription_message": "search_subscriptions.daily_subscription_message",
    "delete": "search_subscriptions.delete",
    "delete_error": "search_subscriptions.delete_error",
    "delete_success": "search_subscriptions.delete_success",
    "disable_all_subscriptions": "search_subscriptions.disable_all_subscriptions",
    "email_invalid": "search_subscriptions.email_invalid",
    "enable_all_subscriptions": "search_subscriptions.enable_all_subscriptions",
    "for": "search_subscriptions.for",
    "here": "search_subscriptions.here",
    "monthly": "search_subscriptions.monthly",
    "monthly_subscription_message": "search_subscriptions.monthly_subscription_message",
    "my_news_subscriptions": "search_subscriptions.my_news_subscriptions",
    "no_subscriptions_found": "search_subscriptions.no_subscriptions_found",
    "no_subscriptions_message_1": "search_subscriptions.no_subscriptions_message_1",
    "no_subscriptions_message_2": "search_subscriptions.no_subscriptions_message_2",
    "no_validated_emails_message": "search_subscriptions.no_validated_emails_message",
    "on_hold": "search_subscriptions.on_hold",
    "or_navigate_to_here": "search_subscriptions.or_navigate_to_here",
    "retrieve_email_error": "search_subscriptions.retrieve_email_error",
    "retrieve_error": "search_subscriptions.retrieve_error",
    "retrieve_subscriptions_error": "search_subscriptions.retrieve_subscriptions_error",
    "save": "search_subscriptions.save",
    "save_error": "search_subscriptions.save_error",
    "search": "search_subscriptions.search",
    "send_emails_to_address": "search_subscriptions.send_emails_to_address",
    "send_emails_to_address_placeholder": "search_subscriptions.send_emails_to_address_placeholder",
    "send_frequency_question": "search_subscriptions.send_frequency_question",
    "send_frequency_title": "search_subscriptions.send_frequency_title",
    "send_messages_to": "search_subscriptions.send_messages_to",
    "subscribe_to_all_error": "search_subscriptions.subscribe_to_all_error",
    "subscribe_to_all_news": "search_subscriptions.subscribe_to_all_news",
    "subscription_cancel_mesage": "search_subscriptions.subscription_cancel_mesage",
    "subscription_cancellation_info": "search_subscriptions.subscription_cancellation_info",
    "subscription_confirmation": "search_subscriptions.subscription_confirmation",
    "subscription_confirmation_message": "search_subscriptions.subscription_confirmation_message",
    "subscription_delete_error": "search_subscriptions.subscription_delete_error",
    "subscription_delete_success": "search_subscriptions.subscription_delete_success",
    "subscription_name_label": "search_subscriptions.subscription_name_label",
    "subscription_name_placeholder": "search_subscriptions.subscription_name_placeholder",
    "subscription_restore_error": "search_subscriptions.subscription_restore_error",
    "subscription_restore_success": "search_subscriptions.subscription_restore_success",
    "subscription_update_error": "search_subscriptions.subscription_update_error",
    "subscription_update_success": "search_subscriptions.subscription_update_success",
    "subscriptions": "search_subscriptions.subscriptions",
    "subscriptions_not_found_message": "search_subscriptions.subscriptions_not_found_message",
    "subscriptions_not_found_subtitle": "search_subscriptions.subscriptions_not_found_subtitle",
    "subscriptions_not_found_title": "search_subscriptions.subscriptions_not_found_title",
    "undo": "search_subscriptions.undo",
    "unsubscribe_message": "search_subscriptions.unsubscribe_message",
    "update_error": "search_subscriptions.update_error",
    "update_success": "search_subscriptions.update_success",
    "updated_error": "search_subscriptions.updated_error",
    "updated_success": "search_subscriptions.updated_success",
    "weekly": "search_subscriptions.weekly",
    "weekly_subscription_message": "search_subscriptions.weekly_subscription_message"
  },
  "side_pane": {
    "close": "side_pane.close",
    "pin_sidepane": "side_pane.pin_sidepane",
    "toggle_sidepane": "side_pane.toggle_sidepane"
  },
  "status": {
    "latestlbk": "status.latestlbk",
    "coming": "status.coming",
    "effective": "status.effective",
    "historic": "status.historic",
    "unknown": "status.unknown",
    "previousversion": "status.previousversion"
  },
  "taxonomies": {
    "DokumentType": "taxonomies.DokumentType",
    "emne": "taxonomies.emne",
    "kilde": "taxonomies.kilde",
    "knowledge_base": "taxonomies.knowledge_base",
    "year": "taxonomies.year",
    "customer_type": "taxonomies.customer_type",
    "groups": "taxonomies.groups"
  },
  "taxon_page": {
    "search_button_text": "taxon_page.search_button_text",
    "document_tile_header": "taxon_page.document_tile_header",
    "no_taxonomy_error_tile_header": "taxon_page.no_taxonomy_error_tile_header",
    "no_taxonomy_error_tile_message": "taxon_page.no_taxonomy_error_tile_message"
  },
  "tiles": {
    "deep_link_error": "tiles.deep_link_error",
    "execute_search_error": "tiles.execute_search_error",
    "get_search_view_error": "tiles.get_search_view_error",
    "go_to_product": "tiles.go_to_product",
    "login": "tiles.login",
    "query_deserialize_error": "tiles.query_deserialize_error",
    "show_more": "tiles.show_more",
    "tile_loading_error": "tiles.tile_loading_error"
  },
  "validation": {
    "field_required_error": "validation.field_required_error",
    "max_length_error": "validation.max_length_error"
  }
};