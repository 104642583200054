import { SendDocumentOpenedGTMEvent } from "../google-tag-manager-utils";
import { useEffect } from "react";
import { useUserContext } from "../user/use-user-context";
import { useDocumentInfo } from "./document-context";

export function useDocumentOpenedGTMEvent() {
  const { title, taxons } = useDocumentInfo();
  const { user } = useUserContext();
  useEffect(() => {
    const documentType = taxons.find(x => x.taxonomyName === "DokumentType");
    const documentTypeTaxonKey = documentType?.taxonKey
      ? documentType.taxonKey
      : "";

    const documentTypeTaxonTitle = documentType?.title
      ? documentType.title
      : "";

    const organizationName = user?.organizationName;
    if (organizationName) {
      SendDocumentOpenedGTMEvent(
        title,
        documentTypeTaxonKey,
        documentTypeTaxonTitle,
        organizationName
      );
    }
  }, [title, taxons, user]);
}
