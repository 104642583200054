import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetNewRelativeDocumentUrl } from "../../../../document-page/document-page-helpers";
import ChipLabel from "./ChipLabel";
import RelationChip from "./RelationChip";

interface RelationChipContainerProps {
  relationGroupName?: string;
  relationGroupNameTranslationKey?: string;
  uniqueRelatedDocumentsCount: number;
  sectionId: string;
  showAll: boolean;
}
const RelationChipContainer: React.FC<RelationChipContainerProps> = ({
  relationGroupName,
  relationGroupNameTranslationKey,
  uniqueRelatedDocumentsCount,
  sectionId,
  showAll
}) => {
  // Split the URL into two parts: the base URL and the hash (if present)
  const urlParts = useGetNewRelativeDocumentUrl({
    relgroup: relationGroupName || "",
    relsection: sectionId,
    relshowall: showAll
  }).split("#");

  const [url, hash] = urlParts;

  const { push, replace } = useHistory();
  const location = useLocation();

  const open = useCallback(() => {
    // If a hash is present in the URL, replace it with an empty hash in the location object
    // This prevents the page from scrolling to the hash location
    if (hash) {
      replace({
        ...location,
        hash: ""
      });
    }
    push(url);
  }, [push, replace, hash, location, url]);

  return (
    <RelationChip
      key={relationGroupName}
      name={relationGroupName}
      label={
        <ChipLabel
          translationKey={relationGroupNameTranslationKey}
          relationGroupName={relationGroupName}
        />
      }
      relationCount={uniqueRelatedDocumentsCount}
      onClick={() => open()}
    />
  );
};

export default RelationChipContainer;
