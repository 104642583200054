import { DocumentSectionDto } from "@rhinestone/portal-web-api";
import {
  SelectableTreeItemData,
  TreeItemData,
  mapToSelectableTreeItemData
} from "../../../ui-components/TreeView";

export function mapToTreeItems(
  sections: DocumentSectionDto[],
  customKeyFunction?: (item: TreeItemData) => string
): SelectableTreeItemData[] {
  const treeItemData = sections.map(toTreeItemData);
  return mapToSelectableTreeItemData(
    treeItemData,
    undefined,
    customKeyFunction
  );
}

const toTreeItemData = (item: DocumentSectionDto): TreeItemData => ({
  value: item.sectionId,
  label: item.sectionTitle,
  children: item.children.map(toTreeItemData)
});

export const isAllSectionsSelected = (
  sections: DocumentSectionDto[] = [],
  selectedSectionIds: string[]
) => {
  const sectionIds = flatten(sections).map(s => s.sectionId);
  return sectionIds.every(i => selectedSectionIds.includes(i));
};

export const getTopLevelItems = (
  items: DocumentSectionDto[] | undefined,
  ids: string[]
) => {
  const allItems = flatten(items).filter(s => ids.includes(s.sectionId));
  const allChildren = allItems.flatMap(i => i.children).map(i => i.sectionId);
  const isChildOfOtherItem = (item: DocumentSectionDto) =>
    Boolean(allChildren.includes(item.sectionId));
  return allItems.filter(item => !isChildOfOtherItem(item));
};

const flatten = (items: DocumentSectionDto[] = []): DocumentSectionDto[] => {
  return items.reduce(
    (prev, item) => [...prev, ...flatten(item.children)],
    items
  );
};
