import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { Alert } from "../../ui-components/Alert/Alert";

export const SubscriptionInfoBox: React.FC = () => (
  <Alert
    severity="info"
    variant="standard"
    title={
      <Translate
        id={
          translationKeys.document_page.document_family_subscription_info_header
        }
      />
    }
  >
    <Translate
      id={translationKeys.document_page.document_family_subscription_info_text}
    />
  </Alert>
);
