export function SendDocumentOpenedGTMEvent(
  documentTitle: string,
  documentTypeTaxonKey: string,
  documentTypeTaxonTitle: string,
  organizationName: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).dataLayer.push({
    event: "documentOpened",
    documentTitle,
    // We are sending the document key twice for backwards compatibility
    // - from before both the key and title were send
    documentType: documentTypeTaxonKey,
    documentTypeTaxonKey,
    documentTypeTaxonTitle,
    organizationName: organizationName
  });
}

export function SendTermsSearchGTMEvent(
  searchTerm: string,
  organizationName: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).dataLayer.push({
    event: "termSearchTriggered",
    searchTerm,
    organizationName: organizationName
  });
}
