import * as React from "react";
import FormActions from "../../ui-components/forms/controls/FormActions";
import { cancelLabel, submitLabel as defaultSubmitLabel } from "./FormLabels";

interface FormActionsProps {
  onCancel: () => void;
  submitLabel?: string | JSX.Element | undefined;
}

const LocalFormActions: React.FC<FormActionsProps> = ({
  onCancel,
  submitLabel
}) => {
  return (
    <FormActions
      onCancel={onCancel}
      submitLabel={submitLabel || defaultSubmitLabel}
      cancelLabel={cancelLabel}
    />
  );
};

export default LocalFormActions;
