import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

export const cancelLabel = (
  <Translate
    id={
      translationKeys.document_page
        .document_family_subscription_form_cancel_label
    }
  />
);

export const submitLabel = (
  <Translate
    id={
      translationKeys.document_page
        .document_family_subscription_form_submit_label
    }
  />
);
