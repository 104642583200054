import {
  PdfPageDestination,
  FitTypes
} from "./LegacyPdfViewer/pdfjs-react-bridge-helpers/scroll-helpers";

export function getPageDestinationFromHash(
  hash: string
): PdfPageDestination | undefined {
  const [pagePart, destinationPart] = hash.split("&");

  const [, pageNumber] = pagePart.split("=");

  const parsedPageNumber = Number(pageNumber || undefined);

  // if no valid page number in hash, so we cannot set page
  if (isNaN(parsedPageNumber)) return undefined;

  // check if hash contains destination part else just return page number
  if (!destinationPart) return parsedPageNumber;

  // try to parse destination part of hash
  return createPdfPageDestination(parsedPageNumber, destinationPart);
}

function createPdfPageDestination(
  pageNumber: number,
  destinationPart: string
): PdfPageDestination {
  const [, destinationString] = destinationPart.split("=");

  const [fitType, x, y, zoom] = destinationString.split(",");

  if (
    !(<any>Object).values(FitTypes).includes(fitType) ||
    isNaN(Number(x)) ||
    isNaN(Number(y)) ||
    isNaN(Number(zoom))
  )
    return pageNumber;

  return {
    pageNumber: pageNumber,
    destination: {
      fitType: fitType as FitTypes,
      x: Number(x),
      y: Number(y),
      zoom: Number(zoom)
    }
  };
}
