import { MuiThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import { RhinestoneTheme } from ".";
import {
  CssBaseline,
  createMuiTheme,
  lighten,
  Theme,
  Color
} from "@material-ui/core";
import { BreakpointValues } from "@material-ui/core/styles/createBreakpoints";
import { purple } from "./Colors";
import { amber, blue, green, grey, red } from "@material-ui/core/colors";
import { PaletteColor } from "@material-ui/core/styles/createPalette";
import { create } from "lodash";

interface Props {
  rhinestoneTheme: RhinestoneTheme;
  children: React.ReactNode;
}

// Augment default theme with Rhinestone specific properties
declare module "@material-ui/core/styles/createMuiTheme" {
  // Consider moving all the colors defined outside of the Palette, into the Palette, so all colors are defined in our Palette.
  interface Theme {
    // Background color of a highlighted text, used in inline notes
    highlightColor: React.CSSProperties["color"];
    userAnnotationHighlightColor: React.CSSProperties["color"];
    assetAnnotationHighlightColor: React.CSSProperties["color"];
    userPdfAnnotationHighlightColor: React.CSSProperties["color"];
    assetPdfAnnotationHighlightColor: React.CSSProperties["color"];
    borderColor: React.CSSProperties["color"];
    // "alternativeBackgroundColor" would be better suited placed inside the Palette, but it is placed here for simplicity. In the future it should be moved.
    alternativeBackgroundColor: React.CSSProperties["color"];
  }
  interface ThemeOptions {
    highlightColor: React.CSSProperties["color"];
    userAnnotationHighlightColor: React.CSSProperties["color"];
    assetAnnotationHighlightColor: React.CSSProperties["color"];
    borderColor: React.CSSProperties["color"];
    alternativeBackgroundColor: React.CSSProperties["color"];
  }
}

export const ThemeProvider = ({ rhinestoneTheme, children }: Props) => {
  const theme = React.useMemo(() => {
    return createMuiTheme(createRhinestoneTheme(rhinestoneTheme));
  }, [rhinestoneTheme]);

  return (
    <MuiThemeProvider theme={theme}>
      {/* CssBaseline is needed to enable styling of for example body element */}
      <CssBaseline>{children}</CssBaseline>
    </MuiThemeProvider>
  );
};

function createPaletteColor(color: Color): PaletteColor {
  return {
    main: color[500],
    light: color[200],
    dark: color[800]
  } as unknown as PaletteColor;
}

export function createRhinestoneTheme(rhinestoneTheme: RhinestoneTheme): Theme {
  return {
    highlightColor: "#fff8d1",
    userAnnotationHighlightColor: "#fff8d1",
    assetAnnotationHighlightColor: "#c3ffd2",
    // pdf highlight colors need to be able to vary from html since it is an overlay with opacity set
    userPdfAnnotationHighlightColor: "yellow",
    assetPdfAnnotationHighlightColor: "green",
    borderColor: "#00A7B5",
    alternativeBackgroundColor: lighten(rhinestoneTheme.primaryColor, 0.9),
    palette: {
      primary: {
        main: rhinestoneTheme.primaryColor
      },
      secondary: {
        main: rhinestoneTheme.secondaryColor
      },
      background: { default: "#fff" },
      status: {
        unknown: createPaletteColor(grey),
        effective: createPaletteColor(green),
        historic: createPaletteColor(red),
        coming: createPaletteColor(amber),
        latestLbk: createPaletteColor(blue),
        previousVersion: createPaletteColor(purple),
        appealed: createPaletteColor(blue)
      }
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      // Document title
      h1: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "2.5rem",
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
        wordBreak: "break-word"
      },
      // Various document headers
      h2: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "2.5rem",
        marginTop: "1.3rem",
        marginBottom: "1.3rem"
      },
      h3: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "2rem",
        marginTop: "1rem",
        marginBottom: "1rem"
      },
      h4: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "1.6rem",
        marginTop: "0.8rem",
        marginBottom: "0.8rem"
      },
      h5: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "1.3rem",
        marginTop: "0.6rem",
        marginBottom: "0.6rem"
      },
      h6: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontSize: "1rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem"
      },
      button: {
        fontFamily: ["Titillium Web", "sans-serif"].join(","),
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "uppercase"
      }
    },
    overrides: {
      MuiIconButton: {
        sizeSmall: {
          padding: 6
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 1165,
        md: 1453,
        lg: 1624,
        xl: 2000
      } as unknown as BreakpointValues
    }
  } as unknown as Theme;
}
