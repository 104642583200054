import { FormControlLabel } from "@material-ui/core";
import * as React from "react";
import Checkbox from "../../ui-components/Checkbox/Checkbox";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

interface SubscribeToEntireDocumentProps {
  isChecked: boolean;
  onChange: () => void;
}

const SubscribeToEntireDocument: React.FunctionComponent<SubscribeToEntireDocumentProps> =
  ({ isChecked, onChange }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={() => onChange()}
            color="primary"
          />
        }
        label={
          <Translate
            id={
              translationKeys.document_page
                .document_family_subscription_form_subscribe_to_entire_document
            }
          />
        }
      />
    );
  };

export default SubscribeToEntireDocument;
