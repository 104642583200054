import * as _ from "lodash";
import { Criteria } from "./criterias";
import { SearchRequestClient, SearchRequestServer } from "./search-request";

export class SearchRequestMapper {
  public static mapToServerSearchRequest(
    request: SearchRequestClient
  ): SearchRequestServer {
    return SearchRequestMapper.mapToServerSearchRequestInternal(request, true);
  }

  public static mapToServerSearchRequestWithoutSkipAndTake(
    request: SearchRequestClient
  ): SearchRequestServer {
    return SearchRequestMapper.mapToServerSearchRequestInternal(request, false);
  }

  public static mapToServerRequestCriteria(
    criteriaList: ReadonlyArray<Criteria>
  ): { [providerKey: string]: Criteria[] } {
    const criteria: { [providerKey: string]: Criteria[] } = {};
    const groupedCriteria = _.groupBy(criteriaList, "providerKey");
    for (const key in groupedCriteria) {
      criteria[key] = _.map(
        groupedCriteria[key],
        // In case this breaks something, notice that this line used to be:
        //    (_.isString(x.data) ? x.data : JSON.stringify(x.data))
        // Why would we double stringify the server-requests? Depending on server-implementation, this change might break
        // something, but this should be fixed in the BE, not here. Send data "raw", not as string, unless it is strings.
        x => x.data
      );
    }
    return criteria;
  }

  private static mapToServerSearchRequestInternal(
    request: SearchRequestClient,
    mapSkipAndTake: boolean
  ): SearchRequestServer {
    const searchRequestServer: SearchRequestServer = {
      fieldSetName: request.fieldSetName,
      page: request.page
    };

    // server won't allow using ordering and formatter at the same time
    if (
      request.formatter !== undefined ||
      request.localformatter !== undefined
    ) {
      searchRequestServer.formatter = request.formatter;
      searchRequestServer.localformatter = request.localformatter;
    } else {
      searchRequestServer.ordering = request.ordering;
      searchRequestServer.slices = request.slices;
      searchRequestServer.snippets = request.snippets;
    }

    if (mapSkipAndTake) {
      searchRequestServer.skip = request.skip;
      searchRequestServer.take = request.take;
    }

    searchRequestServer.criteria = this.mapToServerRequestCriteria(
      request.criteria
    );
    return searchRequestServer;
  }
}
