import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import FormToggleButtonGroupField from "../../ui-components/forms/controls/FormToggleButtonGroupField";
import { ToggleButton } from "@material-ui/lab";
import { DocumentFamilySubscriptionFormFieldKeys } from "./TypedForm";

interface SubscriptionSelectFormFieldProps {
  fieldName: DocumentFamilySubscriptionFormFieldKeys;
  label?: React.ReactNode;
  defaultValue: SubscriptionFrequency;
}

export enum SubscriptionFrequency {
  None,
  Daily,
  Weekly,
  Monthly
}

export const SubscriptionFrequencySelectFormField: React.FC<SubscriptionSelectFormFieldProps> =
  ({ fieldName, label, defaultValue }) => {
    const options = [
      {
        value: SubscriptionFrequency.Daily,
        translationKey:
          translationKeys.document_page
            .document_family_subscription_frequency_daily
      },
      {
        value: SubscriptionFrequency.Weekly,
        translationKey:
          translationKeys.document_page
            .document_family_subscription_frequency_weekly
      },
      {
        value: SubscriptionFrequency.Monthly,
        translationKey:
          translationKeys.document_page
            .document_family_subscription_frequency_monthly
      }
    ];

    return (
      <Translate>
        {({ translate }) => (
          <>
            <FormToggleButtonGroupField
              fieldName={fieldName}
              label={label}
              exclusive={true}
              value={defaultValue}
            >
              {options &&
                options.map((option, idx) => (
                  <ToggleButton key={idx} value={option.value}>
                    {translate(option.translationKey)}
                  </ToggleButton>
                ))}
            </FormToggleButtonGroupField>
          </>
        )}
      </Translate>
    );
  };
