import { PdfAnnotationModel } from "@rhinestone/portal-web-api";
import { AnnotationLayerFactory } from "./annotation-layer-factory";
import { PdfSelection } from "./pdf-selection";
import { Rect } from "./rect";

export interface AnnotationViewModel {
  annotation: PdfAnnotationModel;
  highlightColor: string;
}

export class PdfPageAnnotationLayer {
  public trackSelection?: boolean;
  private isDragging?: boolean;
  private isMouseDown?: boolean;
  private selectionRect!: Rect;
  private canvasContext!: CanvasRenderingContext2D;
  private viewport: any;
  public annotationViewModels: AnnotationViewModel[];

  public canvas: HTMLCanvasElement;
  public div: HTMLElement;
  mouseDownHandler: (e: MouseEvent) => void;
  mouseUpHandler: (e: MouseEvent) => void;
  mouseLeaveHandler: () => void;
  mouseMoveHandler: (e: MouseEvent) => void;
  contextMenuHandler: (e: MouseEvent) => void;

  constructor(
    public pageIndex: number,
    private annotationContainer: HTMLElement,
    private layerFactory: AnnotationLayerFactory
  ) {
    console.debug(
      "PdfPageAnnotationLayer: creating new layer for page",
      pageIndex
    );
    this.annotationViewModels = new Array<AnnotationViewModel>();

    // Scoped event handlers
    this.mouseDownHandler = this.mouseDown.bind(this);
    this.mouseUpHandler = this.mouseUp.bind(this);
    this.mouseMoveHandler = this.mouseMove.bind(this);
    this.mouseLeaveHandler = this.mouseLeave.bind(this);
    this.contextMenuHandler = this.contextMenu.bind(this);

    // create canvas element on which we write annotations
    this.canvas = document.createElement("canvas");
    this.canvas.className = "annotationLayer";
    const canvasContext = this.canvas.getContext("2d");
    if (!canvasContext)
      throw Error(
        "Canvas context returned from canvas.getContext is null which is unexpected. Please review this code, and find solution. Maybe move call to getContext back to setupAnnotations."
      );
    this.canvasContext = canvasContext;
    this.div = this.canvas;
  }

  /**
   * Method is expected by Pdf.js
   * This is called multiple times for an instance of PdfPageAnnotationLayer
   * for example when rescaling page
   */
  public setupAnnotations(viewport: any) {
    console.debug(
      "PdfPageAnnotationLayer: Setting up annotation layer for page",
      this.pageIndex
    );

    this.viewport = viewport;

    // setupAnnotations are called multiple times for same instance of PdfPageAnnotationLayer
    // appendChild doesn't readd canvas page, but ensures it is last child
    // so it should be safe to call multiple times
    this.annotationContainer.appendChild(this.canvas);
    this.canvas.width = viewport.width;
    this.canvas.height = viewport.height;

    this.canvasContext.globalAlpha = 0.2;
    this.isDragging = false;
    this.isMouseDown = false;

    this.setSelectionStrokeStyle();

    this.toggleCreateAnnotationTracking(this.layerFactory.isVisible);

    // draw any existing annotations
    this.clearSelection(true);
    this.layerFactory.layoutChanged(this.pageIndex);
  }

  /**
   * Method is expected by Pdf.js
   *
   * This is called multiple times for an instance of PdfPageAnnotationLayer
   * for example when rescaling page
   * NOTE: this is NOT called when pdf view is unmounted or when page content is removed due to virtualization
   */
  public hide() {
    console.debug(
      "PdfPageAnnotationLayer: hide called for annotation layer for page",
      this.pageIndex
    );

    // Hide is called by Pdf.js when updating the PdfPageView.
    // At least called when resizing browser window
    this.unsubscribeToPointerEvents();
  }

  public update(viewport: any) {
    // Update is called when browser is resized and the document has updated viewport.
    this.setupAnnotations(viewport);

    if (this.trackSelection) {
      this.subscribeToPointerEvents();
    }

    this.redraw();
  }

  public toggleCreateAnnotationTracking(enabled: boolean) {
    this.trackSelection = enabled;
    this.isMouseDown = false;
    this.clearSelection(true);

    if (this.trackSelection) {
      this.subscribeToPointerEvents();
    } else {
      this.unsubscribeToPointerEvents();
    }
  }

  public redraw() {
    const { width, height } = this.canvas;
    // Clear the whole canvas
    this.canvasContext.clearRect(0, 0, width, height);

    if (!this.layerFactory.isVisible) return;
    // Redraw all annotations
    this.annotationViewModels.forEach(a => {
      this.drawAnnotation(a, this.canvasContext, this.viewport.scale);
    });
  }

  public clearSelection(resetCurrentSelection: boolean) {
    if (resetCurrentSelection) {
      this.selectionRect = new Rect(0, 0, 0, 0);
      this.isDragging = false;
    }

    this.redraw();
  }

  private draw(rect: Rect) {
    this.canvasContext.fillRect(rect.startX, rect.startY, rect.w, rect.h);
  }

  private drawAnnotation(
    annotationViewModel: AnnotationViewModel,
    renderCtx: CanvasRenderingContext2D,
    scale: number
  ) {
    const { annotation, highlightColor } = annotationViewModel;
    const scaledX = annotation.startX * scale;
    const scaledY = annotation.startY * scale;

    renderCtx.lineWidth = 3;
    renderCtx.fillStyle = highlightColor;
    const scaledWidth = (annotation.endX - annotation.startX) * scale;
    const scaledHeight = (annotation.endY - annotation.startY) * scale;
    renderCtx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
  }

  /**
   * This sets new list of annotations without re-rendering
   */
  public loadAnnotations(annotations: AnnotationViewModel[]) {
    this.annotationViewModels = annotations;
  }

  /**
   * This adds an annotation and then re-renders the layer
   * @param pdfAnnotationElement
   */
  public addAnnotation(pdfAnnotationElement: AnnotationViewModel) {
    this.annotationViewModels.push(pdfAnnotationElement);
    this.clearSelection(true);
  }

  public removeAnnotation(annotation: PdfAnnotationModel) {
    this.annotationViewModels = this.annotationViewModels.filter(a => {
      return a.annotation.id !== annotation.id;
    });
    this.clearSelection(true);
  }

  private setSelectionStrokeStyle() {
    this.canvasContext.lineWidth = 1;
    this.canvasContext.fillStyle = "blue";
  }

  private mouseLeave() {
    if (!this.trackSelection) {
      return;
    }

    if (this.isDragging) {
      this.isDragging = false;
      this.isMouseDown = false;
      this.clearSelection(true);
    }
  }

  private mouseDown(e: MouseEvent) {
    // do not handle this event if already tracking or right mouse button
    if (!this.trackSelection || e.button === 2) {
      return;
    }

    this.layerFactory.onSelectionStarted(this);

    const { offsetLeft, offsetTop } = this.canvas;
    const scrollTop =
      document.scrollingElement?.scrollTop === undefined
        ? 0
        : document.scrollingElement?.scrollTop;
    const canvasBoundingRect = this.canvas.getBoundingClientRect();

    this.selectionRect = new Rect(
      e.offsetX - offsetLeft,
      e.pageY - canvasBoundingRect.top - offsetTop - scrollTop,
      0,
      0
    );
    this.isMouseDown = true;
  }

  private contextMenu(e: MouseEvent) {
    if (!this.layerFactory.onContextMenu) return;

    const layerX = (e as any).layerX;
    const layerY = (e as any).layerY;

    if (this.selectionRect.inside(layerX, layerY)) {
      e.preventDefault();
      const scaledRect = this.selectionRect.reScale(this.viewport.scale);
      scaledRect.normalize();
      this.layerFactory.onContextMenu(
        e.x,
        e.y,
        new PdfSelection(scaledRect, this.pageIndex),
        undefined
      );
      return;
    }

    const clickedAnnotation = this.getClickedAnnotation(layerX, layerY);

    if (clickedAnnotation) {
      e.preventDefault();
      this.layerFactory.onContextMenu(
        e.x,
        e.y,
        undefined,
        clickedAnnotation.annotation
      );
    } else {
      this.layerFactory.onContextMenu(e.x, e.y, undefined, undefined);
    }
  }

  private getClickedAnnotation(layerX: number, layerY: number) {
    return this.annotationViewModels.find(m =>
      PdfPageAnnotationLayer.inside(
        m.annotation,
        layerX,
        layerY,
        this.viewport.scale
      )
    );
  }

  private mouseUp(e: MouseEvent) {
    if (!this.trackSelection) {
      return;
    }

    if (this.isDragging) {
      const scaledRect = this.selectionRect.reScale(this.viewport.scale);
      if (Math.abs(scaledRect.w) <= 10 || Math.abs(scaledRect.h) <= 10) {
        this.clearSelection(true);
      }
    } else if (e.button === 0) {
      this.clearSelection(true);
    }

    this.isDragging = false;
    this.isMouseDown = false;
  }

  private mouseMove(e: MouseEvent) {
    if (!this.trackSelection) {
      return;
    }

    e.preventDefault();
    this.isDragging = this.isMouseDown;

    if (this.isDragging) {
      this.layerFactory.onSelection(this);

      const canvasBoundingRect = this.canvas.getBoundingClientRect();
      const scrollTop =
        document.scrollingElement?.scrollTop === undefined
          ? 0
          : document.scrollingElement?.scrollTop;
      const { offsetLeft, offsetTop } = this.canvas;

      this.selectionRect.w = e.offsetX - offsetLeft - this.selectionRect.startX;
      this.selectionRect.h =
        e.pageY -
        canvasBoundingRect.top -
        offsetTop -
        this.selectionRect.startY -
        scrollTop;
      this.clearSelection(false);
      this.setSelectionStrokeStyle();
      this.draw(this.selectionRect);
    }
  }

  private subscribeToPointerEvents() {
    this.canvas.addEventListener("mousedown", this.mouseDownHandler, false);
    this.canvas.addEventListener("mouseup", this.mouseUpHandler, false);
    this.canvas.addEventListener("mousemove", this.mouseMoveHandler, false);
    this.canvas.addEventListener("mouseleave", this.mouseLeaveHandler, false);
    this.canvas.addEventListener("contextmenu", this.contextMenuHandler, false);
  }

  private unsubscribeToPointerEvents() {
    this.canvas.removeEventListener("mousedown", this.mouseDownHandler, false);
    this.canvas.removeEventListener("mouseup", this.mouseUpHandler, false);
    this.canvas.removeEventListener("mousemove", this.mouseMoveHandler, false);
    this.canvas.removeEventListener(
      "mouseleave",
      this.mouseLeaveHandler,
      false
    );
    this.canvas.removeEventListener(
      "contextmenu",
      this.contextMenuHandler,
      false
    );
  }

  // checks if given point is inside pdf annotation
  private static inside(
    annotation: PdfAnnotationModel,
    x: number,
    y: number,
    scale: number
  ): boolean {
    const scaledStartX = annotation.startX * scale;
    const scaledStartY = annotation.startY * scale;
    const scaledEndX = annotation.endX * scale;
    const scaledEndY = annotation.endY * scale;

    return (
      (x - scaledStartX) * (x - scaledEndX) <= 0 &&
      (y - scaledStartY) * (y - scaledEndY) <= 0
    );
  }
}
