import { useFeatureFlag } from "../../../feature-flags/feature-flag/use-feature-flag";
import { useUserContext } from "../../../user/use-user-context";
import { useIsDanishLaw } from "../../AboutDocumentFamily/use-is-danish-law";
import { useDocumentInfo } from "../../document-context";

export function useShouldShowDocumentFamilySubscriptionAction(): boolean {
  const { isAuthenticated } = useUserContext();
  const { isDanishLaw } = useIsDanishLaw();
  const { isEnabled } = useFeatureFlag("ShowDocumentFamilySubscriptionButton");
  const { familyName } = useDocumentInfo();
  return isAuthenticated && isEnabled && isDanishLaw && Boolean(familyName);
}
