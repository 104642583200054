import * as React from "react";
import {
  LocalizeContextProps,
  Translate,
  withLocalize
} from "react-localize-redux";
import { useUserProfile } from "../../user/use-user-profile";
import { DocumentFamilySubscriptionForm } from "./DocumentFamilySubscriptionForm";
import FormModal from "./FormModal";
import { translationKeys } from "../../../gen/translation-keys";
import { useDocumentInfo } from "../document-context";
import {
  useCreateOrUpdateDocumentFamilySubscription,
  useGetAllDocumentSectionsTreeStructure
} from "./helpers";
import { DocumentFamilySubscriptionFormFieldProps } from "./TypedForm";
import { useIsProfileMissingEmail } from "../../account/profile-email-required/use-is-profile-missing-email";
import {
  CreateOrUpdateDocumentFamilySubscriptionDto,
  DocumentFamilySubscriptionDto
} from "@rhinestone/portal-web-api";
import Container from "@material-ui/core/Container/Container";
import Box from "@material-ui/core/Box/Box";
import { ProfileEmailRequired } from "../../account/profile-email-required/ProfileEmailRequired";
import { SubscriptionInfoBox } from "./SubscriptionInfoBox";
import { AdditionalFiltersDto } from "@rhinestone/portal-web-api/dist/gen/portalApi/src/models/mappers";

export interface DocumentFamilySubscriptionDialogProps
  extends LocalizeContextProps {
  existingDocumentFamilySubscription?: DocumentFamilySubscriptionDto;
  isFetching: boolean;
  open: boolean;
  onClose: () => void;
}

const DocumentFamilySubscriptionDialog: React.FC<DocumentFamilySubscriptionDialogProps> =
  ({ open, onClose, existingDocumentFamilySubscription, isFetching }) => {
    const { data: profile } = useUserProfile();
    const { title, fullName } = useDocumentInfo();

    const { mutateAsync: createOrUpdateDocumentFamilySubscription, isLoading } =
      useCreateOrUpdateDocumentFamilySubscription();

    const { data: documentSectionTree } =
      useGetAllDocumentSectionsTreeStructure();

    const isProfileMissingEmail = useIsProfileMissingEmail();

    const handleSubmit = async (
      data: DocumentFamilySubscriptionFormFieldProps
    ) => {
      const dto: CreateOrUpdateDocumentFamilySubscriptionDto = {
        emailAddress: data.email,
        frequency: data.frequency,
        name: data.subscriptionName,
        subscriptionId: existingDocumentFamilySubscription?.subscriptionId,
        additionalFilters: { sectionTree: { sections: data.sections ?? [] } }
      };
      await createOrUpdateDocumentFamilySubscription({ dto });
      onClose();
    };

    return (
      <FormModal
        isLoading={isLoading || isFetching}
        isModalShown={open}
        onClose={onClose}
        title={
          <Translate
            id={
              translationKeys.document_page
                .document_family_subscription_dialog_title
            }
            data={{ lawtitle: title }}
          />
        }
      >
        <Container>
          {isProfileMissingEmail && (
            <Box marginTop={3} marginBottom={3}>
              <ProfileEmailRequired />
            </Box>
          )}

          <Box marginTop={3} marginBottom={3}>
            <SubscriptionInfoBox />
          </Box>

          <DocumentFamilySubscriptionForm
            userEmails={profile?.emailAddresses}
            data={existingDocumentFamilySubscription}
            onCancel={onClose}
            onSubmit={handleSubmit}
            documentSectionTree={documentSectionTree}
            defaultName={(title || fullName).substring(0, 256)}
          />
        </Container>
      </FormModal>
    );
  };

export default withLocalize<DocumentFamilySubscriptionDialogProps>(
  DocumentFamilySubscriptionDialog
);
