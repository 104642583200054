import * as React from "react";
import { ThreePanesLayout } from "../ui-components/layouts/ThreePanesLayout";
import { PdfContent } from "../document/content/pdf/PdfContent";
import { LoadingProgress } from "../ui-components/LoadingProgress";
import { AssetCollectionInfoContainer } from "../document/AssetCollection/AssetCollectionInfoContainer";
import { LegacyPdfViewer } from "../document/content/pdf/LegacyPdfViewer";
import {
  useAssetPdfPageContext,
  useAssetPdfPageState
} from "./asset-pdf-page-helpers";
import { PdfAnnotationContainer } from "../document/annotations/pdf/PdfAnnotationContainer";
import { ArticleContent } from "../ui-components/layouts/ArticleContent";
import { PdfContextMenu } from "../document/context-menu/PdfContextMenu";
import Content from "../document/content/Content";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../gen/translation-keys";
import {
  PdfViewer,
  PdfViewerProps
} from "../document/content/pdf/PdfViewer/PdfViewer";
import { useFeatureFlag } from "../feature-flags/feature-flag/use-feature-flag";

export const AssetPdfPage: React.FC = () => {
  const { assetItem, pdfDownloadUrl, annotationState } =
    useAssetPdfPageContext();

  const { selectedRect, selectedAnnotation, contextMenuPos, onContextMenu } =
    useAssetPdfPageState();

  const { isEnabled: useNewPdfViewer, isLoading: isLoadingUseNewPdfViewer } =
    useFeatureFlag("UseNewPdfViewer");

  const isReadyToRender = assetItem && !isLoadingUseNewPdfViewer;

  const pdfViewerProps: PdfViewerProps = {
    url: pdfDownloadUrl,
    annotations: annotationState,
    onContextMenu: onContextMenu,
    renderAnnotations: layout =>
      annotationState.isShown && (
        <PdfAnnotationContainer
          annotationState={annotationState}
          pdfPagesLayoutState={layout}
        />
      ),
    isAnnotationsVisible: true
  };

  return (
    <Translate>
      {({ translate }) => (
        <ThreePanesLayout
          right={[
            {
              key: "asset-collection-info",
              title: translate(
                translationKeys.document_page.asset_colletion
              ).toString(),
              content: <AssetCollectionInfoContainer />
            }
          ]}
          center={
            isReadyToRender ? (
              <ArticleContent>
                <Content>
                  <PdfContent title={assetItem.name}>
                    {_ => (
                      <PdfContextMenu
                        position={contextMenuPos}
                        pdfAnnotation={selectedAnnotation}
                        selection={selectedRect}
                        annotationState={annotationState}
                      >
                        {useNewPdfViewer ? (
                          <PdfViewer {...pdfViewerProps} />
                        ) : (
                          <LegacyPdfViewer {...pdfViewerProps} />
                        )}
                      </PdfContextMenu>
                    )}
                  </PdfContent>
                </Content>
              </ArticleContent>
            ) : (
              <LoadingProgress />
            )
          }
        />
      )}
    </Translate>
  );
};
