import { RJSFSchema } from "@rjsf/utils";

export const optionsSchema: RJSFSchema = {
  type: "object",
  properties: {
    searchProfile: {
      type: "object",
      properties: {
        indexName: {
          type: "string"
        },
        embeddingModel: {
          type: "string"
        },
        vectorField: {
          type: "string"
        },
        useHybridSearch: {
          type: "boolean"
        },
        semanticConfigurationName: {
          type: "string"
        },
        minimumScore: {
          type: "number"
        },
        minimumRerankerScore: {
          type: "number"
        },
        maxResults: {
          type: "integer"
        }
      },
      required: [
        "indexName",
        "embeddingModel",
        "vectorField",
        "useHybridSearch",
        "semanticConfigurationName"
      ]
    },
    promptProfile: {
      type: "object",
      properties: {
        systemPrompt: {
          type: "string"
        },
        seed: {
          type: "integer"
        },
        maxTokens: {
          type: "integer"
        },
        temperature: {
          type: "number"
        }
      },
      required: ["systemPrompt", "maxTokens", "temperature"]
    }
  },
  required: []
};
