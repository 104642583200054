import React, { useEffect, useState } from "react";
import { FormFieldProps } from "../forms/controls/FormFieldProps";
import { FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Controller, useFormContext } from "react-hook-form";
export interface ToggleButtonGroupBaseProps {
  label?: React.ReactNode;
  "aria-label"?: string;
  items?: { value: unknown; label: React.ReactNode; id?: string }[];
  exclusive?: boolean;
  children?: React.ReactNode;
  value?: string | number;
  onChange?:
    | ((event: React.MouseEvent<HTMLElement>, value: any) => void)
    | undefined;
}

type ToggleButtonGroupProps = ToggleButtonGroupBaseProps & FormFieldProps;

export function ToggleButtonsGroup(props: ToggleButtonGroupProps) {
  const {
    label,
    error,
    helperText,
    exclusive,
    children,
    value,
    name,
    control
  } = props;
  const [toggleGroupValue, setToggleGroupValue] = useState(value);

  const handleValueChanged = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setToggleGroupValue(value);

    if (name && control?.setValue) {
      control.setValue(name, value);
    }
  };

  return (
    <FormControl component="fieldset" error={error} name={name}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Controller
        control={control}
        defaultValue={toggleGroupValue}
        render={() => (
          <>
            <ToggleButtonGroup
              exclusive={exclusive}
              onChange={handleValueChanged}
              value={toggleGroupValue}
            >
              {children}
            </ToggleButtonGroup>
            <FormHelperText>{helperText}</FormHelperText>
          </>
        )}
        name={name ?? "togglebutton"}
      ></Controller>
    </FormControl>
  );
}
