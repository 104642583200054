import { PdfAnnotationModel } from "@rhinestone/portal-web-api";
import { PdfPagesLayoutState } from "../../content/pdf/LegacyPdfViewer/pdf-pages-layout-state";
import { AnnotationLayoutState } from "../annotation-layout-state";

export function convertToLayoutState(
  annotations: PdfAnnotationModel[] | undefined,
  pdfPagesLayoutState: PdfPagesLayoutState | undefined
): AnnotationLayoutState {
  return annotations && pdfPagesLayoutState
    ? annotations
        .filter(a => pdfPagesLayoutState[a.pageIndex + 1])
        .reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: {
              pageIndex: current.pageIndex,
              x: calculateAnnotationLeftOffset(current, pdfPagesLayoutState),
              y: calculateAnnotationTopOffset(current, pdfPagesLayoutState)
            }
          }),
          {}
        )
    : {};
}

function calculateAnnotationTopOffset(
  annotation: PdfAnnotationModel,
  pdfPagesLayoutState: PdfPagesLayoutState
) {
  const pageLayout = pdfPagesLayoutState[annotation.pageIndex + 1];
  if (!pageLayout) return undefined;
  return annotation.startY * pageLayout.viewPortScale + pageLayout.offsetTop;
}

function calculateAnnotationLeftOffset(
  annotation: PdfAnnotationModel,
  pdfPagesLayoutState: PdfPagesLayoutState
) {
  const pageLayout = pdfPagesLayoutState[annotation.pageIndex + 1];
  if (!pageLayout) return undefined;
  return annotation.startX * pageLayout.viewPortScale;
}
