import * as React from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  AiCompletionNamedConfigurationDto,
  ChatMessageDto,
  ChatResponseSourceDto
} from "@rhinestone/portal-web-api";
import { usePortalIdentifier } from "../api-access/use-portal-identifier";

interface AiResponseStreamProps {
  query: string;
  settings: AiCompletionNamedConfigurationDto;
  onCitationsReceived: (citations: ChatResponseSourceDto[]) => void;
  onChatHistoryReceived: (chatHistory: ChatMessageDto[]) => void;
  onResponseChanged: (response: string) => void;
  onStreamingDone: () => void;
}

const AiResponseStream: React.FunctionComponent<AiResponseStreamProps> = ({
  query,
  settings,
  onCitationsReceived,
  onResponseChanged,
  onChatHistoryReceived,
  onStreamingDone
}) => {
  const portalId = usePortalIdentifier();
  React.useEffect(() => {
    const fetchData = async () => {
      const url = createAiSearchUrl(portalId, query);
      await fetchEventSource(url, {
        method: "POST",
        body: JSON.stringify(settings),
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json"
        },
        onopen(res: Response) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client-side error ", res);
          }
          return Promise.resolve();
        },
        onmessage(event) {
          if (event.event === "prompt") {
            const chatHistory: ChatMessageDto[] = JSON.parse(event.data);
            onChatHistoryReceived(chatHistory);
            return;
          }

          if (event.event === "chatResponse") {
            const response = JSON.parse(event.data);
            onResponseChanged(response.content);
            return;
          }

          if (event.event === "citations") {
            const citations: ChatResponseSourceDto[] = JSON.parse(event.data);
            onCitationsReceived(citations);
            return;
          }

          if (event.event === "done") {
            console.log("Streaming done");
            onStreamingDone();
            return;
          }
        },
        onclose() {
          console.log("Connection closed by the server");
        },
        onerror(err: Error) {
          console.log("There was an error from server", err);
        }
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
function createAiSearchUrl(portalId: string, query: string) {
  const encodedQuery = encodeURIComponent(query);
  return `/api/Portals/${portalId}/aiAssistant/completion/v1?query=${encodedQuery}`;
}

export default AiResponseStream;
