import { usePortalApi } from "../../api-access/use-portal-api";
import { useDocumentInfo } from "../document-context";
import { DocumentStatus, getStatusFromFieldset } from "../document-status";

import {
  DocumentHistoryFieldSet,
  FieldNames
} from "./DocumentHistoryTimelineFieldset";
import {
  DocumentHistoryTimelineDto,
  TimelineStatusDto
} from "@rhinestone/portal-web-api";

export interface TimelineSubItemModel {
  label?: string;
  status: DocumentStatus;
  documentId: string;
  date: Date;
  fullName: string;
  hiveId: string;
  lbkLabel?: string;
  hasMoreConnections?: boolean;
}

export interface TimelineItemModel extends TimelineSubItemModel {
  additionalChildren?: TimelineSubItemModel[];
  additionalAncestors?: TimelineSubItemModel[];
}

export function useVersionBasedHistoryTimeline(
  hiveId: string,
  fullName: string,
  familyName: string
) {
  const result = usePortalApi(
    client =>
      client.getVersionBasedHistoryTimeline(hiveId, fullName, familyName),
    // Notice, excluding fullname from cache key
    // this is an optimization so history timeline data is reused across
    // documents in timeline and basically only fetched once
    // version based api uses family name to get timeline so timeline data
    // is the same no matter the document in timeline
    ["getVersionBasedHistoryTimeline", hiveId, familyName]
  );

  return { ...result, data: getItemsFromData(result.data) };
}

export function useReplacedBasedHistoryTimeline(
  hiveId: string,
  fullName: string
) {
  const result = usePortalApi(
    client => client.getBranchingHistoryTimeline(hiveId, fullName),
    ["getBranchingHistoryTimeline", hiveId, fullName]
  );
  return { ...result, data: getItemsFromData(result.data) };
}

function getItemsFromData(data?: DocumentHistoryTimelineDto) {
  if (!data) return [];
  return filterAndMapRelations(data.timeline);
}

/*
  Remove items with no effective date and map to TimelineItemModel
*/
function filterAndMapRelations(
  allRelationsSource: DocumentHistoryFieldSet[]
): TimelineItemModel[] {
  return allRelationsSource
    .filter(
      relation => relation?.documentProperties?.[FieldNames.EffectiveDate]
    ) // Remove relations with no effective date, since we don't know how to handle these in the timeline
    .map(relation => {
      const model: TimelineItemModel = mapBaseDocumentProperties(relation);
      model.additionalChildren = relation?.additionalChildren?.map(
        mapBaseDocumentProperties
      );
      model.additionalAncestors = relation?.additionalAncestors?.map(
        mapBaseDocumentProperties
      );
      return model;
    });
}

function mapBaseDocumentProperties(
  relation: DocumentHistoryFieldSet
): TimelineSubItemModel {
  return {
    documentId: relation?.documentProperties?.[FieldNames.DocumentId],
    date: relation?.documentProperties?.[FieldNames.EffectiveDate],
    status: getStatusFromFieldset(relation?.documentProperties),
    fullName: relation?.documentProperties?.[FieldNames.FullName],
    hiveId: relation?.documentProperties?.[FieldNames.HiveId],
    lbkLabel: relation?.documentProperties?.[FieldNames.LbkDate],
    hasMoreConnections: relation?.hasMoreConnections
  };
}

export function useDocumentHasHistoryTimelineStatus(): {
  data: TimelineStatusDto | undefined;
  isFetching: boolean;
  isError: boolean;
} {
  const { hiveId, fullName } = useDocumentInfo();
  const { data, isFetching, isError } = usePortalApi(
    client => client.getTimelineStatus(hiveId, fullName),
    ["getTimelineStatus", hiveId, fullName]
  );

  return {
    data,
    isFetching,
    isError
  };
}
