import * as React from "react";
import { Translate } from "react-localize-redux";
import FormSelectField from "../../ui-components/forms/controls/FormSelectField";
import { translationKeys } from "../../../gen/translation-keys";
import { DocumentFamilySubscriptionFormFieldKeys } from "./TypedForm";

interface EmailSelectFormFieldProps {
  disabled?: boolean;
  fieldName: DocumentFamilySubscriptionFormFieldKeys;
  options?: string[];
}
const EmailSelectFormField: React.FC<EmailSelectFormFieldProps> = ({
  disabled,
  fieldName,
  options
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <FormSelectField
          disabled={disabled}
          fieldName={fieldName}
          label={translate(
            translationKeys.document_page
              .document_family_subscription_form_email_label
          )}
          fullWidth
          fieldOptions={{
            required: translate(
              translationKeys.document_page
                .document_family_subscription_form_email_required
            ).toString()
          }}
        >
          {options &&
            options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </FormSelectField>
      )}
    </Translate>
  );
};

export default EmailSelectFormField;
