export type DocumentHistoryFieldSet =
  | { [propertyName: string]: any }
  | undefined;

/**
 * TODO: Some fields are "know fields", i.e. they are used in specific components, and have logic. E.g. value of field "abstract" is shown in special abstract component. The value of field "status" has
 * an icon depending on the value.
 * The problem; Different portals have different  metadata-schema and therefore their own naming of fieldset, e.g "abstract" might not be named "abstract" in every portal.
 * Thus making a need for making this configurable. In the future we need to make these "known fields" configurable, so that it can be used generically.
 **/
export enum FieldNames {
  EffectiveDate = "effectiveDate",
  DocumentId = "documentId",
  Status = "status",
  FullName = "fullName",
  HiveId = "hiveId",
  LbkNr = "lbkNr",
  LbkDate = "lbkDate"
}
