import {
  DocumentSectionDto,
  SubscriptionFrequency
} from "@rhinestone/portal-web-api";
import Form, { FormProps } from "../../ui-components/forms/form/Form";

export type DocumentFamilySubscriptionFormFieldProps = {
  email: string;
  subscriptionName: string;
  frequency: SubscriptionFrequency;
  sections: DocumentSectionDto[] | undefined;
};

export type DocumentFamilySubscriptionFormFieldKeys =
  keyof DocumentFamilySubscriptionFormFieldProps;

export const TypedForm: (
  props: FormProps<DocumentFamilySubscriptionFormFieldProps>
) => JSX.Element = props =>
  Form<DocumentFamilySubscriptionFormFieldProps>(props);
