import * as React from "react";
import { DocumentSectionTreeDto } from "@rhinestone/portal-web-api";
import {
  TreeItemData,
  TreeViewMultiSelect
} from "../../../ui-components/TreeView";
import { mapToTreeItems } from "./helpers";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { uniqueId } from "lodash";

interface DocumentSectionsTreeProps {
  sectionTree?: DocumentSectionTreeDto;
  selectedSectionsIds: string[];
  onSectionClick: (sectionIds: string[]) => void;
}

const DocumentSectionsTree: React.FunctionComponent<DocumentSectionsTreeProps> =
  ({ sectionTree, selectedSectionsIds, onSectionClick }) => {
    // Since we need to pass the section ids to API we need the actual
    // section ids to function as unique keys in the tree
    const keyFunction = (item: TreeItemData) =>
      item.value ?? uniqueId("tree-item-");

    const treeItemData = React.useMemo(
      () => mapToTreeItems(sectionTree?.sections ?? [], keyFunction),
      [sectionTree]
    );

    if (!treeItemData || treeItemData.length === 0)
      return (
        <Translate
          id={
            translationKeys.document_page
              .document_family_subscription_form_document_has_no_sections
          }
        />
      );

    return (
      <TreeViewMultiSelect
        items={treeItemData}
        selected={selectedSectionsIds}
        onChange={onSectionClick}
      />
    );
  };

export default DocumentSectionsTree;
