import * as React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";

const DocumentSectionChips: React.FunctionComponent<{ titles: string[] }> = ({
  titles
}) => (
  <Box
    display="flex"
    flexDirection="row"
    gridGap={5}
    flexWrap="wrap"
    marginBottom={3}
  >
    {titles.map((title, index) => (
      <Chip
        key={index}
        color="default"
        size="small"
        variant="outlined"
        label={title}
      />
    ))}
  </Box>
);
export default DocumentSectionChips;
