import { useState } from "react";
import { usePortalApi } from "../api-access/use-portal-api";
import { PdfAnnotationModel } from "@rhinestone/portal-web-api";
import { PdfSelection } from "../document/content/pdf/LegacyPdfViewer/annotations/pdf-selection";
import { ContextMenuPosition } from "../ui-components/ContextMenu/ContextMenu";
import { generateAssetCollectionItemLink } from "../../index.modules";
import { useRelativeApiBasePath } from "../api-access/PortalApiProvider";
import {
  useAssetCollectionContext,
  mapToAssetCollection,
  useAssetItemContext
} from "../document/AssetCollection/asset-collection-context";
import { usePdfAnnotationState } from "../document/annotations/pdf/pdf-annotation-state";

export function useAnnotationTargetForPdfAssetItem(
  assetCollectionId: string | undefined,
  assetItemId: string | undefined
) {
  return usePortalApi(
    client =>
      client.getAnnotationTarget(assetCollectionId ?? "", assetItemId ?? ""),
    ["getAnnotationTarget", assetCollectionId, assetItemId],
    {
      enabled: Boolean(assetCollectionId) && Boolean(assetItemId)
    }
  );
}

export function useAssetPdfPageContext() {
  // fetch asset collection details
  const assetCollectionContext = useAssetCollectionContext();
  const assetCollection = mapToAssetCollection(assetCollectionContext);

  // fetch asset item details
  const assetItem = useAssetItemContext();

  const { data: annotationTargetInfo } = useAnnotationTargetForPdfAssetItem(
    assetCollection?.assetCollectionId,
    assetItem?.itemId
  );

  const portalApiBasePath = useRelativeApiBasePath();

  const pdfDownloadUrl = `${portalApiBasePath}${
    assetCollection &&
    assetItem &&
    generateAssetCollectionItemLink(
      assetCollection.isTemplate,
      assetCollection.assetCollectionId,
      assetCollection.assetCollectionTemplateId,
      String(assetItem.itemId)
    )
  }`;

  const annotationState = usePdfAnnotationState(
    annotationTargetInfo?.annotationTargetId
  );

  return {
    assetItem,
    pdfDownloadUrl,
    annotationState
  };
}

export function useAssetPdfPageState() {
  const [selectedRect, setSelectedRect] = useState<PdfSelection>();
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<PdfAnnotationModel>();
  const [contextMenuPos, setContextMenuPos] = useState<ContextMenuPosition>();

  const onContextMenu = (
    mouseX: number,
    mouseY: number,
    selection?: PdfSelection,
    annotation?: PdfAnnotationModel
  ) => {
    setContextMenuPos({ mouseX, mouseY });
    setSelectedRect(selection);
    setSelectedAnnotation(annotation);
  };

  return { selectedRect, selectedAnnotation, contextMenuPos, onContextMenu };
}
