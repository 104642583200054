import * as React from "react";
import { ModalDialog } from "../../ui-components/ModalDialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OverlayLoader from "../../ui-components/LoadingProgress/OverlayLoader";

interface FormModalProps {
  isModalShown: boolean;
  onClose: () => void;
  isLoading: boolean;
  title: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    minHeight: "300px"
  }
}));

const FormModal: React.FC<FormModalProps> = ({
  isModalShown,
  onClose,
  isLoading,
  children,
  title
}) => {
  const classes = useStyles();
  return (
    <ModalDialog
      title={title}
      open={isModalShown}
      onClose={onClose}
      fullWidth={true}
      className={classes.dialog}
    >
      <OverlayLoader isLoading={isLoading}>{children}</OverlayLoader>
    </ModalDialog>
  );
};

export default FormModal;
