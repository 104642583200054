import { useCallback } from "react";
import {
  PDFProgressDataVersion11534,
  PDFSourceVersion11534
} from "./pdfjs-version-1_1_534-types";
import Mark from "mark.js";
import { SEARCH_MARK_OPTIONS } from "../../../../../browser-utils/useMarkjs";
import { PdfAnnotationState } from "../../../annotations/pdf/pdf-annotation-state";
import { usePdfJsReactAnnotationsBridge } from "./pdfjs-react-bridge-helpers/use-pdfjs-react-annotations-bridge";
import { usePdfJsEvents } from "./pdfjs-react-bridge-helpers/use-pdfjs-events";
import { usePdfjsConfiguration } from "./pdfjs-react-bridge-helpers/use-pdfjs-configuration";
import { useSetupPdfViewer } from "./pdfjs-react-bridge-helpers/use-setup-pdf-viewer";
import {
  handleScrollToDestination,
  PdfPageDestination
} from "./pdfjs-react-bridge-helpers/scroll-helpers";
import { useSetupFindBar } from "./pdfjs-react-bridge-helpers/use-setup-find-bar";
import { usePdfjsPageRenderedHandler } from "./pdfjs-react-bridge-helpers/use-pdfjs-page-rendered-handler";
import { PdfSelection } from "./annotations/pdf-selection";
import { PdfAnnotationModel } from "@rhinestone/portal-web-api";

/**
 * This hook represents the entry point for the "bridge" between React world and pdfjs PdfViewer
 */
export function usePdfJsPdfViewer(
  pdfSource: PDFSourceVersion11534,
  pdfWorkerSrc: string,
  onDownloadProgress: (progress: PDFProgressDataVersion11534) => void,
  onContextMenu?: (
    mouseX: number,
    mouseY: number,
    selection?: PdfSelection,
    annotation?: PdfAnnotationModel
  ) => void,
  findBar?: Element | null,
  highlights?: string[],
  pdfPageDestination?: PdfPageDestination,
  annotationState?: PdfAnnotationState
) {
  usePdfjsConfiguration(pdfWorkerSrc);

  const { pdfViewer, pdfViewContainerRef, annotationLayerFactory } =
    useSetupPdfViewer(pdfSource, onDownloadProgress);

  useSetupFindBar(findBar, pdfViewer);

  if (annotationLayerFactory && onContextMenu) {
    annotationLayerFactory.onContextMenu = onContextMenu;
  }

  usePdfJsReactAnnotationsBridge(annotationState, annotationLayerFactory);

  const { pdfjsPageRenderedHandler, pdfViewHeight, pdfPagesLayoutState } =
    usePdfjsPageRenderedHandler(pdfViewer, pdfViewContainerRef);

  const pdfJsPagesLoadedHandler = useCallback(
    (e: Event) => {
      // initial scroll to page when pdfjs is ready
      handleScrollToDestination(pdfViewer, pdfPageDestination);
      // TODO: maybe handle rescaling and annotations
    },
    [pdfViewer, pdfPageDestination]
  );

  const pdfJsTextLayerRenderedHandler = useCallback(
    (e: Event) => {
      if (!highlights || !highlights.length) return;

      // Unlike the find-bar where we need the number of results up-front, we can actually
      // postpone rendering highlights until a page's text-layer is rendered.
      // We don't handle clearing up the markInstance, so this might be a small memory-leak.
      // If this ever becomes an issue, simply gather all instances in a useState array and
      // clear them on un-mount by using an empty useEffect's callback.
      const markInstance = new Mark(e.target as never);
      markInstance.mark(highlights, {
        ...SEARCH_MARK_OPTIONS,
        // Notice that we use the same class and element-type as PdfJs' own highlight class
        className: "highlight",
        element: "span"
      });
    },
    [highlights]
  );

  // Hook up on PdfJs custom events
  usePdfJsEvents(
    pdfjsPageRenderedHandler,
    pdfJsPagesLoadedHandler,
    pdfJsTextLayerRenderedHandler
  );

  return {
    pdfViewContainerRef,
    pdfViewer,
    pdfViewHeight,
    pdfPagesLayoutState
  };
}
