/* eslint-disable no-constant-condition */
import * as React from "react";
import { PdfContent } from "./PdfContent";
import { Abstract } from "../shared/Abstract";
import { DocumentToolbar } from "../shared/DocumentToolbar";
import { InstanceRelationsContainer } from "../../InstanceRelations";
import { DocumentActionsMenu } from "../../DocumentActionMenu";
import { ArticleRelationChips } from "../shared/document-relation-groups/RelationChips";
import { FindInDocumentStateProvider } from "../../FindInDocumentStateProvider";
import { useLocalNotes } from "../shared/MergeDocument/merge-document-util";
import { Box } from "@material-ui/core";
import { LegacyPdfViewer } from "./LegacyPdfViewer";
import { PdfViewer } from "./PdfViewer";
import { PdfAnnotationContainer } from "../../annotations/pdf/PdfAnnotationContainer";
import { OrphanedAnnotationsContainer } from "../../annotations/OrphanedAnnotationsContainer";
import { PdfContextMenu } from "../../context-menu/PdfContextMenu";
import { PdfPagesLayoutState } from "./LegacyPdfViewer/pdf-pages-layout-state";
import { PdfViewerProps } from "./PdfViewer/PdfViewer";
import {
  usePdfContentContainerContext,
  usePdfContentContainerState
} from "./pdf-content-container-helpers";
import { useFeatureFlag } from "../../../feature-flags/feature-flag/use-feature-flag";

export const PdfContentContainer: React.FC = () => {
  const {
    pdfDownloadUrl,
    title,
    annotationState,
    orphanedAnnotations,
    isAnnotationsVisible,
    highlights,
    destination,
    ticketId
  } = usePdfContentContainerContext();

  const { selectedRect, selectedAnnotation, contextMenuPos, onContextMenu } =
    usePdfContentContainerState();

  const { isEnabled: useNewPdfViewer, isLoading: isLoadingFeatureFlags } =
    useFeatureFlag("UseNewPdfViewer");

  const pdfViewerProps: PdfViewerProps = {
    url: pdfDownloadUrl,
    highlights,
    goToDestination: destination,
    ticketId,
    annotations: annotationState,
    onContextMenu,
    isAnnotationsVisible,
    renderAnnotations: (layout: PdfPagesLayoutState) =>
      annotationState.isShown && (
        <PdfAnnotationContainer
          annotationState={annotationState}
          pdfPagesLayoutState={layout}
        />
      )
  };

  return (
    <FindInDocumentStateProvider>
      {orphanedAnnotations && (
        <OrphanedAnnotationsContainer
          orphanedAnnotations={orphanedAnnotations}
          removeAnnotation={annotationState.removeAnnotation}
        />
      )}
      <PdfContent
        title={title}
        additionalDocumentInformation={
          <>
            <Box marginBottom={3}>
              <ArticleRelationChips />
            </Box>
            <Abstract highlights={highlights} />
          </>
        }
        localNotes={useLocalNotes()}
        highlights={highlights}
        renderArticleActions={props => (
          <DocumentToolbar>
            <DocumentActionsMenu {...props} />
            <div className="instance-tabs">
              <InstanceRelationsContainer variant="scrollable" />
            </div>
          </DocumentToolbar>
        )}
      >
        {findBarElement => (
          <PdfContextMenu
            position={contextMenuPos}
            pdfAnnotation={selectedAnnotation}
            selection={selectedRect}
            annotationState={annotationState}
          >
            {!isLoadingFeatureFlags &&
              (useNewPdfViewer ? (
                <PdfViewer {...pdfViewerProps} findBar={findBarElement} />
              ) : (
                <LegacyPdfViewer {...pdfViewerProps} findBar={findBarElement} />
              ))}
          </PdfContextMenu>
        )}
      </PdfContent>
    </FindInDocumentStateProvider>
  );
};
