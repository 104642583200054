import React from "react";
import { useDocumentInfo } from "../document-context";
import {
  usePortalApi,
  usePortalApiMutation
} from "../../api-access/use-portal-api";
import { CreateOrUpdateDocumentFamilySubscriptionDto } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { useSnackbar } from "../../ui-components/Snackbar";

export function useCreateOrUpdateDocumentFamilySubscription() {
  const { hiveId, familyName } = useDocumentInfo();
  const { enqueueSuccess } = useSnackbar();

  return usePortalApiMutation(
    client =>
      (variables: { dto: CreateOrUpdateDocumentFamilySubscriptionDto }) =>
        client.createOrUpdateDocumentFamilySubscription(
          hiveId,
          familyName ?? "",
          variables.dto
        ),
    [["createOrUpdateDocumentFamilySubscription"]],
    {
      onSuccess: (data, variables) => {
        enqueueSuccess(
          <span>
            <Translate
              id={
                translationKeys.document_page
                  .document_family_subscription_form_success
              }
            />
          </span>
        );
      }
    }
  );
}

export function useGetExistingDocumentFamilySubscription() {
  const { hiveId, familyName } = useDocumentInfo();

  return usePortalApi(
    client =>
      client.getExistingDocumentFamilySubscription(hiveId, familyName ?? ""),
    ["getExistingDocumentFamilySubscription", hiveId, familyName],
    { staleTime: 0 } // Because subscriptions can be deleted from subscription page
  );
}

export function useGetAllDocumentSectionsTreeStructure() {
  const { hiveId, fullName } = useDocumentInfo();

  return usePortalApi(
    client => client.getAllDocumentSectionsTreeStructure(hiveId, fullName),
    ["getAllDocumentSectionsTreeStructure", hiveId, fullName]
  );
}
