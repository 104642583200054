import * as React from "react";
import FormTextField from "../../ui-components/forms/controls/FormTextField";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { DocumentFamilySubscriptionFormFieldKeys } from "./TypedForm";

interface NameFormFieldProps {
  fieldName: DocumentFamilySubscriptionFormFieldKeys;
}

export const SubscriptionNameTextField: React.FC<NameFormFieldProps> = ({
  fieldName
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <FormTextField
          fieldName={fieldName}
          label={translate(
            translationKeys.document_page
              .document_family_subscription_form_name_label
          )}
          fullWidth
          fieldOptions={{
            required: translate(
              translationKeys.document_page
                .document_family_subscription_form_name_required
            ).toString(),
            maxLength: {
              value: 250,
              message: translate(
                translationKeys.document_page
                  .document_family_subscription_form_name_length
              ).toString()
            }
          }}
        />
      )}
    </Translate>
  );
};
