import * as React from "react";
import { useMemo } from "react";
import { PdfPagesLayoutState } from "../../content/pdf/LegacyPdfViewer/pdf-pages-layout-state";
import { AnnotationComments } from "../comments/AnnotationComments";
import { convertToLayoutState } from "./pdf-annotation-layout-helpers";
import { PdfAnnotationState } from "./pdf-annotation-state";

interface PdfAnnotationContainerProps {
  annotationState: PdfAnnotationState;
  pdfPagesLayoutState: PdfPagesLayoutState | undefined;
}

export const PdfAnnotationContainer: React.FC<PdfAnnotationContainerProps> = ({
  annotationState,
  pdfPagesLayoutState
}) => {
  const { annotations, saveAnnotation, removeAnnotationComment } =
    annotationState;
  const layoutState = useMemo(
    () =>
      convertToLayoutState(
        annotations?.map(a => a.annotation),
        pdfPagesLayoutState
      ),
    [annotations, pdfPagesLayoutState]
  );
  return annotations ? (
    <AnnotationComments
      annotations={annotations}
      layoutState={layoutState}
      onSaveAnnotation={saveAnnotation}
      onDeleteAnnotationComment={removeAnnotationComment}
      markerRightPosition={"-40px"}
      // TODO: review if this need to change when upgrading. Depends on scroll chosen solution
      // markerRightPosition={"0"}
    ></AnnotationComments>
  ) : null;
};
