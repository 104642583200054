import { Fade } from "@material-ui/core";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import DocumentFamilySubscriptionDialog from "../../DocumentFamilySubscription/DocumentFamilySubscriptionDialog";
import { useGetExistingDocumentFamilySubscription } from "../../DocumentFamilySubscription/helpers";
import { IconButton } from "../../../ui-components/IconButton/IconButton";

export const DocumentFamilySubscriptionAction: React.FC = props => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const {
    isFetching,
    data: existingDocumentFamilySubscription,
    refetch: refetchDocumentFamilySubscription
  } = useGetExistingDocumentFamilySubscription();

  const handleOnClose = () => {
    refetchDocumentFamilySubscription();
    setIsVisible(false);
  };

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            {...props}
            size="small"
            title={translate(
              translationKeys.document_page
                .document_family_subscription_button_label
            ).toString()}
            onClick={() => setIsVisible(true)}
          >
            <Fade in={true}>
              {existingDocumentFamilySubscription ? (
                <NotificationsIcon color={"primary"} />
              ) : (
                <NotificationsOutlinedIcon />
              )}
            </Fade>
          </IconButton>

          <DocumentFamilySubscriptionDialog
            open={isVisible}
            existingDocumentFamilySubscription={
              existingDocumentFamilySubscription
            }
            isFetching={isFetching}
            onClose={handleOnClose}
          />
        </>
      )}
    </Translate>
  );
};
